import H5 from '@/components/H5';
import { Widgets2ChartData } from '@/feature/dashboard/screens/components/Widgets2';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

interface StateProps {
  currencyChartData: Widgets2ChartData;
}

export const TotalEventsComponent: React.FC<StateProps> = ({ currencyChartData }): JSX.Element => (
  <Col xxl="8" lg="12" className="box-col-12">
    <Card>
      <CardHeader className="card-no-border">
        <H5>Total de eventos</H5>
      </CardHeader>
      <CardBody className="pt-0">
        <Row className="m-0 overall-card">
          <Col xl="12" className="col-xl-12">
            <div className="chart-right">
              <CardBody className="p-0">
                <div className="current-sale-container">
                  <ReactApexChart
                    type="bar"
                    height={300}
                    options={currencyChartData.options}
                    series={currencyChartData.series}
                  />
                </div>
              </CardBody>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
);
