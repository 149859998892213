import React from 'react';
import CloseModal from '@/assets/images/CloseModal';
import { Card, Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Btn from '../Button';

type ButtonTheme = 'primary' | 'secondary' | 'success' | 'info';

export interface ActionProps {
  title?: string;
  theme?: ButtonTheme;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface DialogProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  isContentWithCard?: boolean;
  visible: boolean;
  onClose(): void;
  position: 'center' | 'right';
  footerPosition?: 'center' | 'right' | 'left';
  footerBorder?: 'top' | 'none';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  actions: ActionProps[];
}

export const Dialog: React.FC<DialogProps> = ({
  title,
  children,
  visible,
  onClose,
  isContentWithCard = false,
  position = 'center',
  footerPosition = 'right',
  footerBorder = 'top',
  size = 'xl',
  actions,
}) => {
  const handleClose = (): void => onClose();

  const getFinalTheme = (theme: ButtonTheme): string => {
    const finalTheme = {
      primary: 'btn-primary',
      secondary: 'btn-secondary',
      success: 'btn-success',
      info: 'btn-info',
    }[theme];
    return finalTheme;
  };

  const finalPosition = {
    center: '',
    right: 'flex-modal-right',
  }[position];

  const finalSize = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
  }[size];

  const ftPosition = {
    center: 'justify-content-center',
    left: 'justify-content-left',
    right: 'justify-content-right',
  }[footerPosition];

  const ftBorder = {
    top: 'modal__forter-main',
    none: 'border-0',
  }[footerBorder];

  return (
    <Modal
      modalClassName={finalPosition}
      size={finalSize}
      isOpen={visible}
      toggle={() => onClose()}
    >
      <ModalHeader>
        <div className="modal__header-container">
          {title && <div className="header-title-text modal__title">{title}</div>}
          <div
            className="modal-close-container"
            onClick={() => {
              handleClose();
            }}
            style={{ cursor: 'pointer' }}
          >
            <CloseModal />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className={isContentWithCard ? 'modal__with-container' : ''}>
        <Container>
          {isContentWithCard ? <Card className="modal__main-container">{children}</Card> : children}
        </Container>
      </ModalBody>
      <div style={{ padding: '0 40px' }}>
        {actions.length > 0 && actions[0]?.title && (
          <ModalFooter className={`${ftPosition} ${ftBorder}`} style={{ padding: '30px 0' }}>
            {actions?.map((action, index) => (
              <React.Fragment key={index}>
                {action.title && action.onClick && (
                  <Btn
                    key={action.title}
                    title={action.title}
                    // className={`btn ${action.theme ? getFinalTheme(action.theme) : ''} ${finalSize}`}
                    attrBtn={{
                      color: 'transparent',
                      outline: true,
                      className: `btn ${action.theme ? getFinalTheme(action.theme) : ''} ${finalSize}`,
                      onClick: action.onClick,
                    }}
                    disabled={action.disabled}
                  >
                    {action.title}
                  </Btn>
                )}
              </React.Fragment>
            ))}
          </ModalFooter>
        )}
      </div>
    </Modal>
  );
};
