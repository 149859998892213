import React from 'react';
import H5 from '@/components/H5';
import { Card, CardBody, CardFooter, CardHeader, Col } from 'reactstrap';
import man from '@/assets/images/profile.png';
import Image from '@/components/Image';
import H6 from '@/components/H6';
import { Event } from '@/model/Event';
import { toCurrency } from '@/utils/currency';
import { ShouldShowModal } from '@/feature/cancelItem/types';

interface StateProps {
  event: Event;
}

interface DispatchProps {
  onShouldShowModal: ({
    value,
    newTitleModal,
    eventSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    eventSelected?: Event;
  }) => void;
}

type Props = StateProps & DispatchProps;

export const CardVideo: React.FC<Props> = ({ event, onShouldShowModal }): JSX.Element => (
  <Col className="col-xxl-4 col-sm-6 box-col-6">
    <Card
      className="height-equal"
      onClick={(): void => {
        onShouldShowModal({
          value: ShouldShowModal.coupon,
          newTitleModal: `Cupom nº ${event.couponNumber}`,
          eventSelected: event,
        });
      }}
    >
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 className="m-0">{`Cupom ${event.couponNumber}`}</H5>
          <div className="card-header-right-icon">
            <label className="badge badge-danger">{toCurrency(event.couponValue)}</label>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="main-container-video-image">
          <img
            src={`https://drive.google.com/u/0/drive-usercontent/${event.fileId}=w350-h333-p-k-rw-v1-nu-iv1`}
            crossOrigin="anonymous"
            style={{ maxWidth: '100%' }}
          />
        </div>
      </CardBody>
      <CardFooter className="card-no-border">
        <div className="header-top">
          <div className="recent-table table-responsive">
            <div className="product-content">
              <div className="order-image">
                <Image
                  attrImage={{
                    src: man,
                    alt: 't-shirt',
                  }}
                />
              </div>
              <div>
                <H6 className="f-14 mb-0">{event.operator}</H6>
                <span className="f-light f-12">{event.store}</span>
              </div>
            </div>
          </div>
          <div className="card-header-right-icon">
            <span className="f-light f-12">{event.eventDate}</span>
            <br />
            <span className="f-light f-12">{event.checkout}</span>
          </div>
        </div>
      </CardFooter>
    </Card>
  </Col>
);
