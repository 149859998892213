import H5 from '@/components/H5';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { toCurrency, toPercent } from '@/utils/currency';
import { TotalEventsXBilling } from '@/model/TotalEventsXBilling';
import { Widget } from './Widget';

interface StateProps {
  totalEventsXBilling: TotalEventsXBilling;
}

export const TotalEventsXBillingComponent: React.FC<StateProps> = ({
  totalEventsXBilling,
}): JSX.Element => (
  <Row>
    <Col xl="12" className="col-xl-12">
      <Card>
        <CardHeader className="card-no-border">
          <H5>Total de Eventos X Faturamento</H5>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="m-0 overall-card">
            <Col xxl="2" xl="3" sm="6" className="box-col-6">
              <Widget
                data={{
                  title: 'Valor cancelado',
                  value: toCurrency(totalEventsXBilling.cancelValue),
                  color: 'secondary',
                  icon: 'cart',
                }}
              />
            </Col>
            <Col xxl="2" xl="3" sm="6" className="box-col-6">
              <Widget
                data={{
                  title: 'Qtde. Cupons Canc.',
                  value: `${totalEventsXBilling.amountCouponCancel}`,
                  color: 'secondary',
                  icon: 'tag',
                }}
              />
            </Col>
            <Col xxl="2" xl="3" sm="6" className="box-col-6">
              <Widget
                data={{
                  title: '% Cupons canc.',
                  value: toPercent(totalEventsXBilling.percentCouponCancel / 100),
                  color: 'warning',
                  icon: 'return-box',
                }}
              />
            </Col>
            <Col xxl="2" xl="3" sm="6" className="box-col-6">
              <Widget
                data={{
                  title: 'Qtde. Itens Canc.',
                  value: `${totalEventsXBilling.amountCouponItemCancel}`,
                  color: 'secondary',
                  icon: 'tag',
                }}
              />
            </Col>
            <Col xxl="2" xl="3" sm="6" className="box-col-6">
              <Widget
                data={{
                  title: '% Itens Cancelados',
                  value: toPercent(totalEventsXBilling.percentCouponItemCancel / 100),
                  color: 'warning',
                  icon: 'return-box',
                }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
);
