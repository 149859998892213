import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { REACT_APP_AUTH } from '@/utils/config';
import PrivateRoute from './PrivateRoute';
import { authRoutes } from './AuthRoutes';
import AuthorizedRoutes from './AuthorizedRoutes';

const Routers = (): JSX.Element => {
  const appAuth = String(REACT_APP_AUTH);
  const auth = useState(localStorage.getItem(appAuth));
  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path={'/'} element={<PrivateRoute />}>
          {auth ? (
            <>
              <Route path={`/`} element={<Navigate to={`/dashboard`} />} />
            </>
          ) : (
            ''
          )}
          <Route path={`/*`} element={<AuthorizedRoutes />} />
        </Route>

        {authRoutes.map(({ path, Component }, i) => (
          <Route path={path} element={Component} key={i} />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
