import React, { useContext, useState } from 'react';
import { useForm } from '@/hooks/useForm';
import { AuthRequest } from '@/model/AuthRequest';
import { AuthRequestBody } from '@/model/AuthRequestBody';
import * as validators from '@/utils/validators';
import { REACT_APP_AUTH } from '@/utils/config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { LoaderStatus } from '@/components/Loader';
import CustomizerContext from '@/helper/customizer';
import { ThemeType } from '@/model/ThemeType';
import { LoginUI } from './ui';
import { doLogin } from '../../services';

export const LoginScreen: React.FC = (): JSX.Element => {
  const history = useNavigate();
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const { addMixBackgroundLayout } = useContext(CustomizerContext);
  const { formData, formErrors, onChangeInput, isFormValid, resetForm } = useForm<AuthRequest>({
    initialData: {
      email: '',
      password: '',
    },
    validators: {
      email: [validators.required],
      password: [validators.required /* validators.hasPasswordOnlyNumberCharacteres */],
    },
  });

  const handleOnLoginPress = async (): Promise<void> => {
    if (isFormValid()) {
      setStatus(LoaderStatus.LOADING);
      try {
        const payload: AuthRequestBody = {
          grant_type: 'client_credentials',
        };
        const response = await doLogin(formData.email, formData.password, payload);
        const appAuth = String(REACT_APP_AUTH);
        localStorage.setItem(appAuth, JSON.stringify(response));
        resetForm();
        if (response.user.theme === ThemeType.DARK) {
          addMixBackgroundLayout('dark-only');
          document.body.className = 'dark-only';
        } else {
          addMixBackgroundLayout('light-only');
          document.body.className = 'light-only';
        }

        history('/');
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  return (
    <LoginUI
      status={status}
      logoClassMain={''}
      formData={formData}
      onChangeInput={onChangeInput}
      formErrors={formErrors}
      onLoginPress={handleOnLoginPress}
    />
  );
};
