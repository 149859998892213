import { EventCustom } from '@/model/EventCustom';
import { Page } from '@/model/Page';
import { Product } from '@/model/Product';
import api from '@/services/api';

export const pageCompanyEventCustom = async (
  request: Page<EventCustom, EventCustom>,
): Promise<Page<EventCustom, EventCustom>> => {
  const { data } = await api.post<Page<EventCustom, EventCustom>>(
    '/company-event-custom/v1/page',
    request,
  );
  return data;
};

export const getProduct = async (barcode: string): Promise<Product> => {
  const { data } = await api.get<Product>(`/product/v1/${barcode}`);
  return data;
};

export const getCompanyEventCustom = async (id: string): Promise<EventCustom> => {
  const { data } = await api.get<EventCustom>(`/company-event-custom/v1/${id}`);
  return data;
};

export const saveCompanyEventCustom = async (payload: EventCustom): Promise<void> => {
  if (payload.id) {
    await api.put('/company-event-custom/v1', payload);
  } else {
    await api.post('/company-event-custom/v1', payload);
  }
};

export const deleteCompanyEventCustom = async (id: string): Promise<void> => {
  await api.delete(`/company-event-custom/v1/${id}`);
};
