// eslint-disable-next-line no-shadow
export enum PaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  PIX = 'PIX',
  MONEY = 'MONEY',
  EMPLOYEE_AGREEMENT = 'EMPLOYEE_AGREEMENT',
  POS = 'POS',
  EXCHANGE = 'EXCHANGE',
  UNINFORMED = 'UNINFORMED',
}
