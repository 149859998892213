import React from 'react';
import LI from '@/components/ListGroup/ListItem';
import UL from '@/components/ListGroup/UL';
import Image from '@/components/Image';
import H6 from '@/components/H6';
import SvgIcon from '@/components/SvgIcon';
import User1 from '@/assets/images/user1.jpg';
import { ScheduleListData } from './UpcomingSchedule';

interface Props {
  data: ScheduleListData[];
}

const ScheduleListBox: React.FC<Props> = ({ data }): JSX.Element => (
  <UL attrUL={{ className: 'schedule-list d-flex' }}>
    {data.map((item, i) => (
      <LI key={i} attrLI={{ className: `${item.color}` }}>
        <Image
          attrImage={{
            src: item.image ? item.image : User1,
            alt: 'profile',
          }}
        />
        <div>
          <H6 className="mb-1">{item.title}</H6>
          <UL>
            <LI attrLI={{ className: 'f-light' }}>
              <SvgIcon iconId={item.icon} className="fill-icon f-light" />
              <span>{item.date}</span>
            </LI>
            <LI attrLI={{ className: 'f-light' }}>
              <SvgIcon iconId={item.icon2} className="fill-icon f-success" />
              <span> {item.date2}</span>
            </LI>
          </UL>
        </div>
      </LI>
    ))}
  </UL>
);

export default ScheduleListBox;
