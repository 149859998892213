import React, { useEffect, useState } from 'react';
import { LoaderStatus } from '@/components/Loader';
import { Page } from '@/model/Page';
import { EventCustom } from '@/model/EventCustom';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useForm } from '@/hooks/useForm';
import * as validators from '@/utils/validators';
import { useDialog } from '@/hooks/useDialog';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { StatusType } from '@/model/StatusType';
import { CompanyEventCustomUI } from './ui';
import { FormInputFilter, ShouldShowModal } from '../types';
import {
  deleteCompanyEventCustom,
  getCompanyEventCustom,
  getProduct,
  pageCompanyEventCustom,
  saveCompanyEventCustom,
} from '../services';
import { DeleteContent } from '../components/DeleteContent';

export const CompanyEventCustomScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [page, setPage] = useState<Page<EventCustom, EventCustom>>({
    page: 1,
    pageSize: 10,
  });
  const [eventCustom, setEventCustom] = useState<EventCustom>();

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleOnFetchEvents = async (newPage: Page<EventCustom, EventCustom>): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await pageCompanyEventCustom(newPage);
      setPage(response);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeInput: onChangeInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm<FormInputFilter>({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      filterSearch: [validators.required],
    },
  });

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    onChangeInput: onChangeInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm<EventCustom>({
    initialData: {
      id: '',
      barcode: '',
      name: '',
      status: undefined as unknown as StatusType,
    },
    validators: {
      barcode: [validators.required],
      name: [validators.required],
    },
  });

  const handleOnFindProduct = async (): Promise<void> => {
    if (formDataRegister.barcode && formDataRegister.barcode.trim().length > 0) {
      try {
        setStatus(LoaderStatus.LOADING);
        const response = await getProduct(formDataRegister.barcode);
        onChangeInputRegister('name', response.name);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnGetCompanyEventCustom = async (
    id: string,
    loadFormData: boolean,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getCompanyEventCustom(id);
      if (loadFormData) {
        onChangeInputRegister('id', response.id);
        onChangeInputRegister('barcode', response.barcode);
        onChangeInputRegister('name', response.name);
        onChangeInputRegister('status', response.status);
      }
      setEventCustom(response);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    eventSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    eventSelected?: EventCustom;
  }): Promise<void> => {
    try {
      setShouldShowModal(value);
      onChangeTitle(newTitleModal);
      if (value === ShouldShowModal.register && eventSelected) {
        await handleOnGetCompanyEventCustom(eventSelected.id, true);
      }
      onToggle();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const toStatusType = (value: string): StatusType | undefined => {
    if (value) {
      if (value === 'ACTIVE') {
        return StatusType.ACTIVE;
      }
      if (value === 'INACTIVE') {
        return StatusType.INACTIVE;
      }
      return undefined;
    }
    return undefined;
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValidFilter()) {
      const entity = {} as EventCustom;
      if (formDataFilter.filterSearch === 'barcode') {
        entity.barcode = formDataFilter.inputSearch;
      } else if (formDataFilter.filterSearch === 'name') {
        entity.name = formDataFilter.inputSearch;
      } else if (formDataFilter.filterSearch === 'status') {
        const statusType = toStatusType(formDataFilter.inputSearch);
        if (statusType) {
          entity.status = statusType;
        }
      }
      const newPage: Page<EventCustom, EventCustom> = {
        page: 1,
        pageSize: 10,
        entity,
      };
      onToggle();
      handleOnFetchEvents(newPage);
    }
  };

  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
    resetFormFilter();
    handleOnFetchEvents({
      page: 1,
      pageSize: 10,
    });
  };

  const handleOnPaginationChange = async (newPage: number): Promise<void> => {
    handleOnFetchEvents({
      ...page,
      page: newPage,
    });
  };

  const handleOnSave = async (): Promise<void> => {
    if (isFormValidRegister()) {
      try {
        setStatus(LoaderStatus.LOADING);
        const payload = {
          barcode: formDataRegister.barcode,
          name: formDataRegister.name,
          status: formDataRegister.status,
        } as EventCustom;
        if (formDataRegister.id) {
          payload.id = formDataRegister.id;
        }
        await saveCompanyEventCustom(payload);
        resetFormRegister();
        onToggle();
        handleOnFetchEvents(page);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmDelete = async (selectedEvent: EventCustom): Promise<void> => {
    try {
      await deleteCompanyEventCustom(selectedEvent.id);
      toast.success('PDV excluído com sucesso!');
      handleOnClose();
      handleOnFetchEvents(page);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnShowDelete = (selectedEvent: EventCustom): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDelete(selectedEvent),
        },
      ],
    });
  };

  useEffect(() => {
    handleOnFetchEvents(page);
  }, []);
  return (
    <CompanyEventCustomUI
      status={status}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      formDataFilter={formDataFilter}
      onChangeInputFilter={onChangeInputFilter}
      formErrorsFilter={formErrorsFilter}
      clearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      eventCustom={eventCustom}
      formDataRegister={formDataRegister}
      formErrorsRegister={formErrorsRegister}
      onChangeInputRegister={onChangeInputRegister}
      onFindProduct={handleOnFindProduct}
      onSave={handleOnSave}
      currentPage={page}
      onPaginationChange={handleOnPaginationChange}
      onShowDelete={handleOnShowDelete}
    />
  );
};
