import H5 from '@/components/H5';
import React from 'react';
import { Card, CardHeader, Col, Row } from 'reactstrap';

export const CouponContent: React.FC = (): JSX.Element => (
  <Row className="widget-grid">
    <Col className="col-8">
      <Card className="height-equal">
        <CardHeader className="card-no-border">
          <div className="header-top">
            <H5 className="m-0">Pratiko 03 - Riviera</H5>
            <div className="card-header-right-icon">
              <label className="badge badge-danger">PDV 03</label>
            </div>
          </div>
        </CardHeader>
      </Card>
    </Col>
    <Col className="col-4">
      <Card>
        <CardHeader className="card-no-border">
          <div className="header-top">
            <div className="card-header-right-icon">
              <label className="badge badge-danger">21/07/2024 - 19:50</label>
            </div>
          </div>
        </CardHeader>
      </Card>
    </Col>
  </Row>
);
