import React, { useEffect, useState } from 'react';
import { LoaderStatus } from '@/components/Loader';
import { Page } from '@/model/Page';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useForm } from '@/hooks/useForm';
import * as validators from '@/utils/validators';
import { useDialog } from '@/hooks/useDialog';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { StatusType } from '@/model/StatusType';
import { StoreEventCustom } from '@/model/StoreEventCustom';
import { SimpleStore } from '@/model/SimpleStore';
import { StoreEventCustomUI } from './ui';
import { FormInputFilter, FormInputRegister, ShouldShowModal } from '../types';
import {
  deleteStoreEventCustom,
  getProduct,
  getStoreEventCustom,
  getStores,
  pageStoreEventCustom,
  saveStoreEventCustom,
} from '../services';
import { DeleteContent } from '../components/DeleteContent';

export const StoreEventCustomScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [page, setPage] = useState<Page<StoreEventCustom, StoreEventCustom>>({
    page: 1,
    pageSize: 10,
  });
  const [stores, setStores] = useState<SimpleStore[]>([]);

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleOnFetchEvents = async (
    newPage: Page<StoreEventCustom, StoreEventCustom>,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await pageStoreEventCustom(newPage);
      setPage(response);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeInput: onChangeInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm<FormInputFilter>({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      filterSearch: [validators.required],
      inputSearch: [validators.required],
    },
  });

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    onChangeInput: onChangeInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm<FormInputRegister>({
    initialData: {
      id: '',
      store: '',
      barcode: '',
      name: '',
      status: '',
    },
    validators: {
      store: [validators.required],
      barcode: [validators.required],
      name: [validators.required],
    },
  });

  const handleOnFindStores = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getStores();
      setStores(response);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnFindProduct = async (): Promise<void> => {
    if (formDataRegister.barcode && formDataRegister.barcode.trim().length > 0) {
      try {
        setStatus(LoaderStatus.LOADING);
        const response = await getProduct(formDataRegister.barcode);
        onChangeInputRegister('name', response.name);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnGetStoreEventCustom = async (id: string, loadFormData: boolean): Promise<void> => {
    try {
      await handleOnFindStores();
      setStatus(LoaderStatus.LOADING);
      const response = await getStoreEventCustom(id);
      if (loadFormData) {
        onChangeInputRegister('id', response.id);
        onChangeInputRegister('store', response.store.id);
        onChangeInputRegister('barcode', response.barcode);
        onChangeInputRegister('name', response.name);
        onChangeInputRegister('status', response.status);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    eventSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    eventSelected?: StoreEventCustom;
  }): Promise<void> => {
    try {
      setShouldShowModal(value);
      onChangeTitle(newTitleModal);
      if (value === ShouldShowModal.register) {
        if (eventSelected) {
          await handleOnGetStoreEventCustom(eventSelected.id, true);
        } else {
          await handleOnFindStores();
        }
      }
      onToggle();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const toStatusType = (value: string): StatusType | undefined => {
    if (value) {
      if (value === 'ACTIVE') {
        return StatusType.ACTIVE;
      }
      if (value === 'INACTIVE') {
        return StatusType.INACTIVE;
      }
      return undefined;
    }
    return undefined;
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValidFilter()) {
      const entity = {} as StoreEventCustom;
      if (formDataFilter.filterSearch === 'store') {
        const store = { id: formDataFilter.inputSearch } as SimpleStore;
        entity.store = store;
      } else if (formDataFilter.filterSearch === 'barcode') {
        entity.barcode = formDataFilter.inputSearch;
      } else if (formDataFilter.filterSearch === 'name') {
        entity.name = formDataFilter.inputSearch;
      } else if (formDataFilter.filterSearch === 'status') {
        const statusType = toStatusType(formDataFilter.inputSearch);
        if (statusType) {
          entity.status = statusType;
        }
      }
      const newPage: Page<StoreEventCustom, StoreEventCustom> = {
        page: 1,
        pageSize: 10,
        entity,
      };
      onToggle();
      handleOnFetchEvents(newPage);
    }
  };

  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
    resetFormFilter();
    handleOnFetchEvents({
      page: 1,
      pageSize: 10,
    });
  };

  const handleOnPaginationChange = async (newPage: number): Promise<void> => {
    handleOnFetchEvents({
      ...page,
      page: newPage,
    });
  };

  const handleOnSave = async (): Promise<void> => {
    if (isFormValidRegister()) {
      try {
        setStatus(LoaderStatus.LOADING);
        const payload = {
          store: {
            id: formDataRegister.store,
          },
          barcode: formDataRegister.barcode,
          name: formDataRegister.name,
          status: formDataRegister.status
            ? formDataRegister.status
            : (undefined as unknown as StatusType),
        } as StoreEventCustom;
        if (formDataRegister.id) {
          payload.id = formDataRegister.id;
        }
        await saveStoreEventCustom(payload);
        resetFormRegister();
        onToggle();
        handleOnFetchEvents(page);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmDelete = async (selectedEvent: StoreEventCustom): Promise<void> => {
    try {
      await deleteStoreEventCustom(selectedEvent.id);
      toast.success('Registro excluído com sucesso!');
      handleOnClose();
      handleOnFetchEvents(page);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnShowDelete = (selectedEvent: StoreEventCustom): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDelete(selectedEvent),
        },
      ],
    });
  };

  useEffect(() => {
    handleOnFetchEvents(page);
  }, []);
  return (
    <StoreEventCustomUI
      status={status}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      formDataFilter={formDataFilter}
      onChangeInputFilter={onChangeInputFilter}
      formErrorsFilter={formErrorsFilter}
      clearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      stores={stores}
      formDataRegister={formDataRegister}
      formErrorsRegister={formErrorsRegister}
      onChangeInputRegister={onChangeInputRegister}
      onFindProduct={handleOnFindProduct}
      onSave={handleOnSave}
      currentPage={page}
      onPaginationChange={handleOnPaginationChange}
      onShowDelete={handleOnShowDelete}
    />
  );
};
