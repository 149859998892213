import React, { Fragment, useState, useEffect, useContext } from 'react';
import CustomizerContext from '@/helper/customizer';
import { MENUITEMS, Menu, MenuItem } from './Menu';
import SidebarIcon from '../SidebarIcon';
import SidebarLogo from '../SidebarLogo';
import SidebarMenu from '../SidebarMenu';

const Sidebar = (): JSX.Element => {
  const customizer = useContext(CustomizerContext);
  const { toggleIcon } = useContext(CustomizerContext);
  const id = window.location.pathname.split('/').pop();
  const defaultLayout = Object.keys(customizer.layout);

  const layout = id || defaultLayout;
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  /*
  const [width, setWidth] = useState(0);

  const handleScroll = (): void => {
    const sidebarMain = document.querySelector('.sidebar-main');
    if (sidebarMain) {
      if (window.scrollY > 400) {
        // if (
        //   customizer.settings.sidebar.type.split(' ').pop() ===
        //   'material-type' ||
        //   customizer.settings.sidebar.type.split(' ').pop() ===
        //   'advance-layout'
        // )
        sidebarMain.className = 'sidebar-main hovered';
      } else if (document.getElementById('sidebar-main')) {
        sidebarMain.className = 'sidebar-main';
      }
    }
  };

  const handleResize = (): void => {
    setWidth(window.innerWidth - 500);
  };
  */

  const setNavActive = (menu: Menu, item: MenuItem): void => {
    const newMenus: Menu[] = [];
    mainmenu.forEach(m => {
      const newItems: MenuItem[] = [];
      if (m.Items && m.Items.length > 0) {
        m.Items.forEach(i => {
          const newItem = {
            ...i,
          };
          const activeItem = !item.active;
          if (i.title === item.title) {
            newItem.active = activeItem;
            if (newItem.active) {
              document.querySelector('.sidebar-links')?.classList.add('active');
            } else {
              document.querySelector('.bg-overlay1')?.classList.remove('active');
            }
          } else {
            newItem.active = !activeItem;
            document.querySelector('.bg-overlay1')?.classList.remove('active');
          }
          newItems.push(newItem);
        });
      }
      const newMenu = {
        ...m,
      };
      const activeMenu = !m.active;
      if (menu.menutitle === m.menutitle) {
        newMenu.active = activeMenu;
      } else {
        newMenu.active = !activeMenu;
      }
      if (newItems && newItems.length > 0) {
        newMenu.Items = newItems;
      }
      newMenus.push(newMenu);
    });
    setMainMenu(newMenus);
  };

  useEffect(() => {
    /*
    document.querySelector('.left-arrow')?.classList.add('d-none');
    window.addEventListener('resize', handleResize);
    handleResize();
    const currentUrl = window.location.pathname;
    MENUITEMS.map(items => {
      items.Items.filter(Items => {
        if (Items.path === currentUrl) setNavActive(Items);
        if (!Items.children) return false;
        Items.children.filter(subItems => {
          if (subItems.path === currentUrl) setNavActive(subItems as MenuItem);
          if (!subItems.children) return false;
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems as MenuItem);
              return true;
            }
            return false;
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
    */
  }, [layout]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const activeClass = (value: boolean | undefined): void => {
    // document.querySelector('.sidebar-link').classList.add('active');
    document.querySelector('.bg-overlay1')?.classList.add('active');
  };

  const closeOverlay = (): void => {
    document.querySelector('.bg-overlay1')?.classList.remove('active');
    document.querySelector('.sidebar-links')?.classList.remove('active');
  };

  return (
    <Fragment>
      <div
        className="bg-overlay1"
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div
        className={`sidebar-wrapper ${toggleIcon ? 'close_icon' : ''}`}
        // eslint-disable-next-line react/no-unknown-property
        sidebar-layout="stroke-svg"
      >
        <SidebarIcon />
        <SidebarLogo />
        {/* sidebartoogle={sidebartoogle} */}
        <SidebarMenu
          menus={mainmenu}
          // setMainMenu={setMainMenu}
          sidebartoogle={false}
          setNavActive={setNavActive}
          activeClass={activeClass}
          // width={width}
          width={window.innerWidth - 500}
        />
      </div>
    </Fragment>
  );
};

export default Sidebar;
