import React, { useEffect, useState } from 'react';
import { Event } from '@/model/Event';
import { Page } from '@/model/Page';
import { LoaderStatus } from '@/components/Loader';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import { useForm } from '@/hooks/useForm';
import * as validators from '@/utils/validators';
import { BleedingUI } from './ui';
import { pageEvent } from '../services';
import { ShouldShowModal } from '../types';

export const BleedingScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [page, setPage] = useState<Page<Event, Event>>({
    page: 1,
    pageSize: 50,
  });
  const [events, setEvents] = useState<Event[]>([]);
  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const handleOnFetchEvents = async (newPage: Page<Event, Event>): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await pageEvent('BLEEDING', newPage);
      setPage(response);
      if (response.list && response.list.length > 0) {
        setEvents(response.list);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const { formData, formErrors, onChangeInput, isFormValid, resetForm } = useForm<Event>({
    initialData: {
      id: '',
      couponNumber: 0,
      couponValue: 0,
      fileId: '',
      operator: '',
      store: '',
      eventDate: '',
      checkout: '',
      stars: 0,
      saveOccurrence: false,
      deviation: false,
      operationalError: false,
      observation: '',
    },
    validators: {
      eventDate: [validators.required],
    },
  });

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValid()) {
      onToggle();
      try {
        setStatus(LoaderStatus.LOADING);
        const newPage: Page<Event, Event> = {
          page: 1,
          pageSize: 50,
          entity: formData,
        };
        const response = await pageEvent('BLEEDING', newPage);
        setPage(response);
        if (response.list && response.list.length > 0) {
          setEvents(response.list);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };
  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
    resetForm();
    handleOnFetchEvents({
      page: 1,
      pageSize: 50,
    });
  };

  useEffect(() => {
    handleOnFetchEvents({
      page: 1,
      pageSize: 50,
    });
  }, []);
  return (
    <BleedingUI
      status={status}
      amount={page.total ? page.total : 0}
      events={events}
      title={title as string}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      formData={formData}
      formErrors={formErrors}
      onChangeInput={onChangeInput}
      onFilter={handleOnFilter}
      clearFilter={handleOnClearFilter}
      onShouldShowModal={handleOnShouldShowModal}
    />
  );
};
