import { InputText } from '@/components/InputText';
import { FormErrors } from '@/hooks/useForm';
import { EventCustom } from '@/model/EventCustom';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';

interface StateProps {
  formData: EventCustom;
  formErrors: FormErrors<EventCustom>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof EventCustom, V>(name: N, value: V) => void;
  onFindProduct: () => void;
}

type Props = StateProps & DispatchProps;

export const RegisterContent: React.FC<Props> = ({
  formData,
  formErrors,
  onChangeInput,
  onFindProduct,
}): JSX.Element => (
  <Fragment>
    <Row>
      <Col md={8}>
        <FormGroup className="mb-2">
          <InputText
            name="barcode"
            label="Código de barras"
            placeholder="Digite o código de barras"
            value={formData.barcode}
            onChange={e => onChangeInput('barcode', e.target.value)}
            onBlur={onFindProduct}
            error={formErrors.barcode && formErrors.barcode[0]}
          />
        </FormGroup>
      </Col>
    </Row>
    {formData.barcode && (
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="name"
              label="Nome do produto"
              value={formData.name}
              onChange={e => onChangeInput('name', e.target.value)}
              error={formErrors.name && formErrors.name[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
    )}
  </Fragment>
);
