import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import H5 from '@/components/H5';
import UL from '@/components/ListGroup/UL';
import LI from '@/components/ListGroup/ListItem';
import H4 from '@/components/H4';
import DropdownCommon from '@/components/DropdownCommon';
import { toCurrency } from '@/utils/currency';
import { Widgets2ChartData } from './Widgets2';

interface Props {
  recentOrderChart: Widgets2ChartData;
  cancelCoupons: number;
  cancelCouponItems: number;
}

const RecentOrders: React.FC<Props> = ({
  recentOrderChart,
  cancelCoupons,
  cancelCouponItems,
}): JSX.Element => (
  <Col xxl="4" xl="7" md="6" sm="5" className="box-col-6">
    <Card className="height-equal">
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5>Cancelamentos período</H5>
          <div className="card-header-right-icon">
            <DropdownCommon
              dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
              options={['Semanal', 'Mensal', 'Anual']}
              iconName="icon-more-alt"
              btn={{ tag: 'span' }}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <Row className="recent-wrapper">
          <Col xl="6">
            <div className="recent-chart">
              <ReactApexChart
                type="radialBar"
                height={290}
                options={recentOrderChart.options}
                series={recentOrderChart.series}
              />
            </div>
          </Col>
          <Col xl="6">
            <UL attrUL={{ className: 'order-content' }}>
              <LI>
                <span className="recent-circle bg-primary"> </span>
                <div>
                  <span className="f-light f-w-500">Volor cupons cancelados</span>
                  <H4 className="mt-1 mb-0">
                    {`${toCurrency(cancelCoupons)}`}
                    <span className="f-light f-14 f-w-400 ms-1">(Ontem)</span>
                  </H4>
                </div>
              </LI>
              <LI>
                <span className="recent-circle bg-info" />
                <div>
                  <span className="f-light f-w-500">Valor itens cancelados</span>
                  <H4 className="mt-1 mb-0">
                    {`${toCurrency(cancelCouponItems)}`}
                    <span className="f-light f-14 f-w-400 ms-1">(Ontem)</span>
                  </H4>
                </div>
              </LI>
            </UL>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
);

export default RecentOrders;
