import React, { Fragment } from 'react';
import { SaveStore } from '../../types';

interface StateProps {
  stores: SaveStore[];
}

interface DispatchProps {
  onChangeUserStore: (index: number, selected: boolean) => void;
}

type Props = StateProps & DispatchProps;

export const StoreContent: React.FC<Props> = ({ stores, onChangeUserStore }): JSX.Element => (
  <Fragment>
    {stores &&
      stores.length > 0 &&
      stores.map((data, index) => (
        <div key={`index-${index}-${data.name}`} className="checkbox-list">
          <input
            name={`store`}
            type="checkbox"
            checked={data.exists}
            onChange={e => onChangeUserStore(index, e.target.checked)}
          />
          <span className="checkbox-list-label">{data.name}</span>
        </div>
      ))}
  </Fragment>
);
