import React, { Fragment, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import CustomizerContext from '@/helper/customizer';
import AnimationThemeContext from '@/helper/AnimationTheme';

import Taptop from '../TapTop';
import Header from '../Header';
import ConfigDB from '../ThemeConfig';
import Footer from '../Footer';
import Sidebar from '../Sidebar';

const AppLayout = ({ ...rest }): JSX.Element => {
  const { layout, sidebarIconType } = useContext(CustomizerContext);

  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const sideBarIcon = localStorage.getItem('sidebar_icon_type') || sidebarIconType;
  const location = useLocation();
  const { animation } = useContext(AnimationThemeContext);
  const animationTheme =
    localStorage.getItem('animation') || animation || ConfigDB.data.router_animation;

  return (
    <Fragment>
      <Taptop />
      <div
        className={`page-wrapper ${layout1}`}
        // eslint-disable-next-line react/no-unknown-property
        sidebar-layout={sideBarIcon}
        id="pageWrapper"
      >
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <TransitionGroup {...rest}>
            <CSSTransition
              key={location.key}
              timeout={100}
              classNames={animationTheme}
              unmountOnExit
            >
              <div className="page-body">
                <div>
                  <div>
                    <Outlet />
                  </div>
                </div>
              </div>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
