import H6 from '@/components/H6';
import Image from '@/components/Image';
import React from 'react';
import { MyCourseData } from './MyCourse';

interface Props {
  data: MyCourseData;
}

const CourseCard: React.FC<Props> = ({ data }): JSX.Element => (
  <div className="course-wrapper">
    <div className="course-icon-box">
      <div className="icon-wrap">
        <Image
          attrImage={{
            src: data.courseImage,
            alt: 'clock vector',
          }}
        />
      </div>
      {/* <Image
        attrImage={{
          className: 'arrow-bg',
          src: data.arrowImage,
          alt: 'sqaure border arrow',
        }}
      /> */}
    </div>
    <H6 className="f-14">{data.title}</H6>
  </div>
);

export default CourseCard;
