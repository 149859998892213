const toCurrency = (value: number): string => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formatter.format(value);
};

const toPercent = (value: number): string => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
    style: 'percent',
    currency: 'BRL',
  });
  return formatter.format(value);
};

export { toCurrency, toPercent };
