import { Widgets2ChartData } from '@/feature/dashboard/screens/components/Widgets2';
import { EventsXBilling } from '@/model/EventsXBilling';
import { TotalEventsXBilling } from '@/model/TotalEventsXBilling';

export const initialTotalEventsChartData: Widgets2ChartData = {
  series: [{ name: 'Eventos', data: [0, 0, 0, 0, 0] }],
  options: {
    chart: {
      height: 300,
      type: 'bar',
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        // enabledOnSeries: undefined,
        top: 10,
        left: 0,
        blur: 5,
        color: '#7064F5',
        opacity: 0.35,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Canc. Cupom', 'Canc. Itens', 'Descontos', 'Personaliz.', 'Sangria'],
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Rubik, sans-serif',
          colors: 'var(--chart-text-color)',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter(val) {
          return `${val}`;
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Rubik, sans-serif',
          colors: 'var(--chart-text-color)',
        },
      },
    },
    grid: {
      borderColor: 'var(--chart-dashed-border)',
      strokeDashArray: 5,
    },
    colors: ['#7064F5', '#8D83FF'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        gradientToColors: ['#7064F5', '#8D83FF'],
        opacityFrom: 0.98,
        opacityTo: 0.85,
        stops: [0, 100],
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
  },
};

export const initialEventsXBilling: EventsXBilling = {
  billing: 0,
  averageTicket: 0,
  amountCoupons: 0,
  amountEvents: 0,
};

export const initialTotalEventsXBilling: TotalEventsXBilling = {
  cancelValue: 0,
  amountCouponCancel: 0,
  percentCouponCancel: 0,
  amountCouponItemCancel: 0,
  percentCouponItemCancel: 0,
};

export interface WidgetData {
  title: string;
  value: string;
  color: string;
  icon: string;
}

export interface TotalStoreDataRow {
  storeAndSaleValue: string;
  amountCouponsAndAverageTicket: string;
  amountCancelCouponsAndAmountEvents: string;
  cancelValue: string;
  percentCancelCouponAndPercenteCancelSale: string;
  amountCancelItensAndCancelItensValue: string;
  percentCancelItemAndPercenteCancelItemSale: string;
  amountDiscountsAndDiscountsValue: string;
  percentDiscountsAndPercenteDiscountsSale: string;
}

export interface TotalOperatorDataRow {
  storeAndOperator: string;
  amountCouponsAndAverageTicket: string;
  amountCancelCouponsAndAmountEvents: string;
  cancelValueAndSaleValue: string;
  percentCancelCouponAndPercenteCancelSale: string;
  amountCancelItensAndCancelItensValue: string;
  percentCancelItemAndPercenteCancelItemSale: string;
  amountDiscountsAndDiscountsValue: string;
  percentDiscountsAndPercenteDiscountsSale: string;
}
