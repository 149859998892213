import axios, { AxiosRequestHeaders, AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { REACT_APP_AUTH, REACT_APP_BASE_URL, REACT_APP_SUFFIX_BASE_URL } from '@/utils/config';
import { AuthResponse } from '@/model/AuthResponse';
import handleSweetAlert from '@/helper/SweetAlerts';

const baseUrl = String(REACT_APP_BASE_URL);
const prefixBaseUrl = String(REACT_APP_SUFFIX_BASE_URL);
const appAuth = String(REACT_APP_AUTH);

export type AxiosErrorResponse = {
  message: string | string[];
};

const createHeader = (): AxiosRequestHeaders => {
  const storageAuthString = localStorage.getItem(appAuth);
  const headers: AxiosRequestHeaders = {
    'Content-Type': 'application/json',
  } as AxiosRequestHeaders;
  if (storageAuthString) {
    const authData = JSON.parse(storageAuthString) as AuthResponse;
    headers.Authorization = `Bearer ${authData.token}`;
  }
  return headers;
};

const api = axios.create({
  baseURL: baseUrl + prefixBaseUrl,
  responseType: 'json',
  headers: createHeader(),
});

api.interceptors.request.use(config => {
  if (config.url !== '/auth/v1') {
    // eslint-disable-next-line no-param-reassign
    config.headers = createHeader();
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  async error => {
    const { response } = error;

    if (response.status === 400) {
      if (response.data && response.data.details) {
        let details = `${response.data.message}: `;

        response.data.details.forEach((detail: string) => {
          details += `${detail}\n`;
        });
        toast.warn(details);
      } else {
        toast.warn(response.data.message);
      }
    }

    if (response.status >= 500 && response.status < 600) {
      toast.error('Opss... Erro interno, tente novamente mais tarde!');

      // eslint-disable-next-line no-param-reassign
      error.message = error.response.data.message as string;
    }

    if (response.status === 401) {
      localStorage.removeItem(appAuth);
      handleSweetAlert(
        'warn',
        'Opss...',
        'Foi detectado um grande periodo de inatividade, logue-se novamente!',
      );
      setTimeout(() => {
        window.location.reload();
      }, 4000);
      return Promise.reject(error);
    }
    if (response.status === 403) {
      handleSweetAlert(
        'warn',
        'Opss...',
        null,
        'Você não possui acesso a essa funcionalidade, contacte o administrador!',
      );
      return Promise.reject(
        new Error('Você não possui acesso a essa funcionalidade, contacte o administrador!'),
      );
    }

    if (response.status !== 401 && response?.data?.errors?.length) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export { AxiosError };

export default api;
