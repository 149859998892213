import React from 'react';
import { Col, Row } from 'reactstrap';
import Widgets1 from './Widgets1';
import Widgets2, { Widgets2Data } from './Widgets2';

export interface WidgetsData {
  title: string;
  gros: number;
  total: number;
  color: string;
  icon: string;
}

interface StateProps {
  widgetsData: WidgetsData;
  widgetsData2: WidgetsData;
  widgetsData3: WidgetsData;
  widgetsData4: WidgetsData;
  widgets2Data: Widgets2Data;
  widgets2Data2: Widgets2Data;
}

const WidgetsWrapper: React.FC<StateProps> = ({
  widgetsData,
  widgetsData2,
  widgetsData3,
  widgetsData4,
  widgets2Data,
  widgets2Data2,
}): JSX.Element => (
  <>
    <Col xxl="auto" xl="3" sm="6" className="box-col-6">
      <Row>
        <Col xl="12">
          <Widgets1 data={widgetsData} />
        </Col>
        <Col xl="12">
          <Widgets1 data={widgetsData2} />
        </Col>
      </Row>
    </Col>
    <Col xxl="auto" xl="3" sm="6" className="box-col-6">
      <Row>
        <Col xl="12">
          <Widgets1 data={widgetsData3} />
        </Col>
        <Col xl="12">
          <Widgets1 data={widgetsData4} />
        </Col>
      </Row>
    </Col>
    <Col xxl="auto" xl="12" sm="6" className="box-col-6">
      <Row>
        <Col xxl="12" xl="6" className="box-col-12">
          <Widgets2 data={widgets2Data} />
        </Col>
        <Col xxl="12" xl="6" className="box-col-12">
          <Widgets2 chartClass="profit-chart " data={widgets2Data2} />
        </Col>
      </Row>
    </Col>
  </>
);

export default WidgetsWrapper;
