import { SimpleStore } from '@/model/SimpleStore';
import { User } from '@/model/User';
import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  register = 'register',
  store = 'store',
}

export interface FormInputFilter {
  filterSearch: string;
  inputSearch: string;
}

export interface FromInputRegister {
  id: string;
  store: string;
  name: string;
  email: string;
  password: string;
  status: string;
  telephone: string;
  birthday: string;
  profilePicture: string;
  theme: string;
  profile: string;
}

export interface DataRow extends User {
  actions: string;
}

export interface SaveStore extends SimpleStore {
  exists: boolean;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Nome',
    selector: row => row.name,
    width: '120px',
  },
  {
    name: 'E-mail',
    selector: row => row.email,
  },
  {
    name: 'Perfil',
    selector: row => row.profile,
  },
  {
    name: 'Status',
    selector: row => row.status,
    width: '100px',
  },
  {
    name: 'Ações',
    selector: row => row.actions,
    width: '90px',
  },
];
