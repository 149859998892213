import H4 from '@/components/H4';
import SvgIcon from '@/components/SvgIcon';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { WidgetData } from '../types';

interface Props {
  data: WidgetData;
}

export const Widget: React.FC<Props> = ({ data }): JSX.Element => (
  <Card className="widget-1">
    <CardBody>
      <div className="widget-content">
        <div className={`widget-round ${data.color}`}>
          <div className="bg-round">
            <SvgIcon className="svg-fill" iconId={`${data.icon}`} />
            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
          </div>
        </div>
        <div>
          <H4>{data.value}</H4>
          <span className="f-light">{data.title}</span>
        </div>
      </div>
    </CardBody>
  </Card>
);
