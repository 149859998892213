import React, { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  iconId: string;
}

const SvgIcon = (props: Props): JSX.Element => {
  const { iconId, ...res } = props;
  return (
    <svg {...res}>
      <use href={`/svg/sprite.svg#${iconId}`}></use>
    </svg>
  );
};

export default SvgIcon;
