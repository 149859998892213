import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import ReactApexChart from 'react-apexcharts';
import H5 from '@/components/H5';
import UL from '@/components/ListGroup/UL';
import LI from '@/components/ListGroup/ListItem';

import LightCard from './LightCard';
import { LightCardData } from './LightCardBox';
import { Widgets2ChartData } from './Widgets2';

interface Props {
  lightCardData: LightCardData[];
  currencyChartData: Widgets2ChartData;
}

const OverallBalance: React.FC<Props> = ({ lightCardData, currencyChartData }): JSX.Element => (
  <Col xxl="8" lg="12" className="box-col-12">
    <Card>
      <CardHeader className="card-no-border">
        <H5>Eventos Diários</H5>
      </CardHeader>
      <CardBody className="pt-0">
        <Row className="m-0 overall-card">
          <Col xl="9" md="12" sm="7" className="p-0">
            <div className="chart-right">
              <Row>
                <Col xl="12" className="col-xl-12">
                  <CardBody className="p-0">
                    <UL attrUL={{ horizontal: true, className: 'd-flex balance-data' }}>
                      <LI>
                        <span className="circle bg-warning"> </span>
                        <span className="f-light ms-1">Anterior</span>
                      </LI>
                      <LI>
                        <span className="circle bg-primary"> </span>
                        <span className="f-light ms-1">Atual</span>
                      </LI>
                    </UL>
                    <div className="current-sale-container">
                      <ReactApexChart
                        type="bar"
                        height={300}
                        options={currencyChartData.options}
                        series={currencyChartData.series}
                      />
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </Col>
          <LightCard data={lightCardData} />
        </Row>
      </CardBody>
    </Card>
  </Col>
);

export default OverallBalance;
