import { Coupon } from '@/model/Coupon';
import { Event } from '@/model/Event';
import { EventInformation } from '@/model/EventInformation';
import { Page } from '@/model/Page';
import api from '@/services/api';

export const pageEvent = async (
  eventType: string,
  request: Page<Event, Event>,
): Promise<Page<Event, Event>> => {
  const { data } = await api.post<Page<Event, Event>>(`/event/v1/page/${eventType}`, request);
  return data;
};

export const getCoupon = async (eventId: string): Promise<Coupon> => {
  const { data } = await api.get<Coupon>(`/event/v1/coupon/${eventId}`);
  return data;
};

export const saveEventInformation = async (
  eventId: string,
  payload: EventInformation,
): Promise<void> => {
  await api.patch<Coupon>(`/event/v1/coupon/${eventId}`, payload);
};
