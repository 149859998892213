import DropdownCommon from '@/components/DropdownCommon';
import H5 from '@/components/H5';
import H6 from '@/components/H6';
import LI from '@/components/ListGroup/ListItem';
import UL from '@/components/ListGroup/UL';
import P from '@/components/P';
import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';

export interface ActivityData {
  title: string;
  subTitle: string;
  time: string;
  dis: string;
  color: string;
}

interface Props {
  activityData: ActivityData[];
}

const ActivityCard: React.FC<Props> = ({ activityData }): JSX.Element => (
  <Col xxl="4" xl="5" md="6" sm="7" className="notification box-col-6">
    <Card className="height-equal">
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 className="m-0">Eventos recentes</H5>
          <div className="card-header-right-icon">
            <DropdownCommon
              icon={false}
              options={['Hoje', 'Amanhã', 'Ontem']}
              btn={{ caret: true }}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <UL>
          {activityData.map((item, i) => (
            <LI key={i} attrLI={{ className: 'd-flex' }}>
              <div className={`activity-dot-${item.color}`} />
              <div className="w-100 ms-3">
                <P attrPara={{ className: 'd-flex justify-content-between mb-2' }}>
                  <span className="date-content light-background">{item.subTitle}</span>
                  <span>{item.time}</span>
                </P>
                <H6>
                  {item.title}
                  <span className="dot-notification" />
                </H6>
                <p className="f-light">{item.dis}</p>
              </div>
            </LI>
          ))}
        </UL>
      </CardBody>
    </Card>
  </Col>
);

export default ActivityCard;
