import { CustomError } from '@/model/CustomError';
import { AxiosError } from 'axios';

export const getErrorMessage = (error: AxiosError<CustomError>): string => {
  let { message } = error;
  if (error.response && error.response.data) {
    if (error.response.data.details && error.response.data.details.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < error.response.data.details.length; i++) {
        if (i === 0) {
          message = error.response.data.details[i];
          break;
        }
      }
    } else if (error.response.data.message) {
      message = error.response.data.message;
    }
  }
  return message;
};
