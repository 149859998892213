import { EventCustom } from '@/model/EventCustom';
import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  register = 'register',
}

export interface FormInputFilter {
  filterSearch: string;
  inputSearch: string;
}

export interface DataRow extends EventCustom {
  actions: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Cód. Barra',
    selector: row => row.barcode,
    width: '120px',
  },
  {
    name: 'Nome',
    selector: row => row.name,
  },
  {
    name: 'Status',
    selector: row => row.status,
    width: '100px',
  },
  {
    name: 'Ações',
    selector: row => row.actions,
    width: '80px',
  },
];
