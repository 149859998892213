import { Page } from '@/model/Page';
import { Product } from '@/model/Product';
import { SimpleStore } from '@/model/SimpleStore';
import { StoreEventCustom } from '@/model/StoreEventCustom';
import api from '@/services/api';

export const pageStoreEventCustom = async (
  request: Page<StoreEventCustom, StoreEventCustom>,
): Promise<Page<StoreEventCustom, StoreEventCustom>> => {
  const { data } = await api.post<Page<StoreEventCustom, StoreEventCustom>>(
    '/store-event-custom/v1/page',
    request,
  );
  return data;
};

export const getProduct = async (barcode: string): Promise<Product> => {
  const { data } = await api.get<Product>(`/product/v1/${barcode}`);
  return data;
};

export const getStores = async (): Promise<SimpleStore[]> => {
  const { data } = await api.get<SimpleStore[]>('/store-event-custom/v1/store/get');
  return data;
};

export const getStoreEventCustom = async (id: string): Promise<StoreEventCustom> => {
  const { data } = await api.get<StoreEventCustom>(`/store-event-custom/v1/${id}`);
  return data;
};

export const saveStoreEventCustom = async (payload: StoreEventCustom): Promise<void> => {
  if (payload.id) {
    await api.put('/store-event-custom/v1', payload);
  } else {
    await api.post('/store-event-custom/v1', payload);
  }
};

export const deleteStoreEventCustom = async (id: string): Promise<void> => {
  await api.delete(`/store-event-custom/v1/${id}`);
};
