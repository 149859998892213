import { REACT_APP_AUTH } from '@/utils/config';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = (): JSX.Element => {
  const appAuth = String(REACT_APP_AUTH);
  const [auth, setAuth] = useState(localStorage.getItem(appAuth));

  useEffect(() => {
    setAuth(localStorage.getItem(appAuth));
  }, []);
  return auth ? <Outlet /> : <Navigate to={`/login`} />;
};

export default PrivateRoute;
