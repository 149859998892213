import React from 'react';

const CloseModal = (): JSX.Element => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66263 7.00028L13.6504 11.988C14.1165 12.4542 14.1165 13.1845 13.6504 13.6506C13.1842 14.1168 12.4539 14.1168 11.9877 13.6506L7 8.66263L2.01226 13.6504C1.54609 14.1165 0.8158 14.1165 0.349629 13.6504C-0.116543 13.1842 -0.116543 12.4539 0.349629 11.9877L5.33737 7L0.349629 2.01226C-0.116543 1.54609 -0.116543 0.8158 0.349629 0.349629C0.8158 -0.116543 1.54609 -0.116543 2.01226 0.349629L7 5.33737L11.9875 0.349629C12.4536 -0.116543 13.1839 -0.116543 13.6501 0.349629C14.1163 0.8158 14.1163 1.54609 13.6501 2.01226L8.66263 7.00028Z"
      fill="#828282"
    />
  </svg>
);

export default CloseModal;
