import { FormErrors } from '@/hooks/useForm';
import React, { Fragment } from 'react';
import { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale/pt-BR';
import { Form } from 'reactstrap';
import { Radio } from '@/components/Radio';
import { InputText } from '@/components/InputText';
import { OptionProps, SelectAutoComplete } from '@/components/Select';
import { FormInputFilter } from '../../types';

registerLocale('ptBR', ptBR);

interface StateProps {
  formData: FormInputFilter;
  formErrors: FormErrors<FormInputFilter>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof FormInputFilter, V>(name: N, value: V) => void;
}

type Props = StateProps & DispatchProps;

export const FilterContent: React.FC<Props> = ({
  formData,
  onChangeInput,
  formErrors,
}): JSX.Element => {
  const optionFilter = [
    { label: 'Código de barras', value: 'barcode' },
    { label: 'Nome', value: 'name' },
    { label: 'Status', value: 'status' },
  ];
  const getPlaceHolder = (value: string): string => {
    let s = 'Nome do PDV';
    if (value === 'barcode') {
      s = 'Informe o código de barras';
    } else if (value === 'name') {
      s = 'Informe o nome do produto';
    } else if (value === 'status') {
      s = 'Selecione o Status';
    }
    return s;
  };
  const statusOptions: OptionProps[] = [
    {
      value: 'ACTIVE',
      label: 'Ativo',
    },
    {
      value: 'INACTIVE',
      label: 'Inativo',
    },
  ];
  return (
    <div className="filter-modal-content">
      <Form noValidate={true} onSubmit={(e): void => e.preventDefault()}>
        <span className="filter-name">Filtrar por:</span>
        <Radio
          options={optionFilter}
          name="filterSearch"
          style={{ fontSize: '21px' }}
          value={formData.filterSearch}
          onChange={e => {
            onChangeInput('filterSearch', e.target.value);
          }}
          error={formErrors.filterSearch && formErrors.filterSearch[0]}
        />
        {formData.filterSearch && (
          <Fragment>
            {formData.filterSearch === 'barcode' || formData.filterSearch === 'name' ? (
              <InputText
                name="inputSearch"
                label={
                  optionFilter.find(values => formData.filterSearch === values.value)?.label ?? ''
                }
                placeholder={getPlaceHolder(formData.filterSearch)}
                maxLength={30}
                value={formData.inputSearch}
                onChange={e => onChangeInput('inputSearch', e.target.value)}
                error={formErrors.inputSearch && formErrors.inputSearch[0]}
              />
            ) : (
              <SelectAutoComplete
                label="Status"
                options={statusOptions}
                name="inputSearch"
                placeholder={getPlaceHolder(formData.filterSearch)}
                defaultValue={formData.inputSearch}
                onChange={value => onChangeInput('inputSearch', value)}
                error={formErrors.inputSearch && formErrors.inputSearch[0]}
              />
            )}
          </Fragment>
        )}
      </Form>
    </div>
  );
};
