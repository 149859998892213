import { createContext } from 'react';

interface Props {
  animation: string;
  routerAnimation: (value: string) => void;
}

const AnimationThemeContext = createContext<Props>(undefined as unknown as Props);

export default AnimationThemeContext;
