import H4 from '@/components/H4';
import H5 from '@/components/H5';
import LI from '@/components/ListGroup/ListItem';
import UL from '@/components/ListGroup/UL';
import { EventsXBilling } from '@/model/EventsXBilling';
import { toCurrency } from '@/utils/currency';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

interface StateProps {
  eventsXBilling: EventsXBilling;
}

export const EventsXBillingComponent: React.FC<StateProps> = ({ eventsXBilling }): JSX.Element => (
  <Col xxl="4" xl="7" md="6" sm="5" className="box-col-6">
    <Card className="height-equal">
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5>Eventos X Faturamento</H5>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <Row className="recent-wrapper">
          <Col xl="6">
            <UL attrUL={{ className: 'order-content' }}>
              <LI>
                <span className="recent-circle bg-primary"> </span>
                <div>
                  <span className="f-light f-w-500">Faturamento</span>
                  <H4 className="mt-1 mb-0">{`${toCurrency(eventsXBilling.billing)}`}</H4>
                </div>
              </LI>
              <LI>
                <span className="recent-circle bg-info" />
                <div>
                  <span className="f-light f-w-500">Qtde. Cupons</span>
                  <H4 className="mt-1 mb-0">{eventsXBilling.amountCoupons}</H4>
                </div>
              </LI>
            </UL>
          </Col>
          <Col xl="6">
            <UL attrUL={{ className: 'order-content' }}>
              <LI>
                <span className="recent-circle bg-primary"> </span>
                <div>
                  <span className="f-light f-w-500">Ticket médio</span>
                  <H4 className="mt-1 mb-0">{`${toCurrency(eventsXBilling.averageTicket)}`}</H4>
                </div>
              </LI>
              <LI>
                <span className="recent-circle bg-info" />
                <div>
                  <span className="f-light f-w-500">Qtde. Eventos</span>
                  <H4 className="mt-1 mb-0">{eventsXBilling.amountEvents}</H4>
                </div>
              </LI>
            </UL>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </Col>
);
