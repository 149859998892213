import Image from '@/components/Image';
import React, { Fragment, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import LoginImage from '@/assets/images/login-image.jpg';
import { Link } from 'react-router-dom';
import logoWhite from '@/assets/images/logo.png';
import logoDark from '@/assets/images/logo_dark.png';
import H4 from '@/components/H4';
import P from '@/components/P';
import Button from '@/components/Button';
import H6 from '@/components/H6';
import { Facebook, Linkedin, Twitter } from 'react-feather';
import { AuthRequest } from '@/model/AuthRequest';
import { FormErrors } from '@/hooks/useForm';
import Loader, { LoaderStatus } from '@/components/Loader';

interface StateProps {
  status: LoaderStatus;
  logoClassMain: string;
  formData: AuthRequest;
  formErrors: FormErrors<AuthRequest>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof AuthRequest, V>(name: N, value: V) => void;
  onLoginPress: () => void;
}

type Props = StateProps & DispatchProps;

export const LoginUI: React.FC<Props> = ({
  status,
  logoClassMain,
  formData,
  onChangeInput,
  onLoginPress,
}): JSX.Element => {
  const [togglePassword, setTogglePassword] = useState(false);
  return (
    <Fragment>
      <Loader status={status} />
      <section>
        <Container fluid={true}>
          <Row>
            <Col
              xl="5"
              className="b-center bg-size"
              style={{
                backgroundImage: `url(${LoginImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'block',
              }}
            >
              <Image
                attrImage={{
                  className: 'bg-img-cover bg-center d-none',
                  src: `${LoginImage}`,
                  alt: 'looginpage',
                }}
              />
            </Col>
            <Col xl="7 p-0">
              <div className="login-card">
                <div>
                  <div>
                    <Link className={`logo ${logoClassMain || ''}`} to={'/'}>
                      <Image
                        attrImage={{
                          className: 'img-fluid for-light',
                          src: logoWhite,
                          alt: 'looginpage',
                        }}
                      />
                      <Image
                        attrImage={{
                          className: 'img-fluid for-dark',
                          src: logoDark,
                          alt: 'looginpage',
                        }}
                      />
                    </Link>
                  </div>

                  <div className="login-main">
                    <div className="theme-form login-form">
                      <H4>Acessar sua conta</H4>
                      <P>Informe seu e-mail e senha</P>
                      <FormGroup>
                        <Label className="col-form-label m-0">Email</Label>
                        <Input
                          className="form-control"
                          type="email"
                          required
                          placeholder="Test@gmail.com"
                          value={formData.email}
                          onChange={e => onChangeInput('email', e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="position-relative">
                        <Label className="col-form-label m-0">Senha</Label>
                        <div className="position-relative">
                          <Input
                            className="form-control"
                            type={togglePassword ? 'text' : 'password'}
                            name="login[password]"
                            required
                            placeholder="*********"
                            value={formData.password}
                            onChange={e => onChangeInput('password', e.target.value)}
                          />
                          <div
                            className="show-hide"
                            onClick={() => setTogglePassword(!togglePassword)}
                          >
                            <span className={togglePassword ? '' : 'show'}></span>
                          </div>
                        </div>
                      </FormGroup>
                      <FormGroup className="position-relative">
                        <div className="checkbox">
                          <Input id="checkbox1" type="checkbox" />
                          <Label className="text-muted" for="checkbox1">
                            Lembrar senha
                          </Label>
                        </div>
                        <Link className="link" to={`/pages/authentication/forget-pwd`}>
                          Esqueceu sua senha?
                        </Link>
                      </FormGroup>
                      <FormGroup>
                        <Button
                          attrBtn={{
                            className: 'd-block w-100 mt-2',
                            color: 'primary',
                            type: 'Button',
                            onClick: () => onLoginPress(),
                          }}
                        >
                          Login
                        </Button>
                      </FormGroup>
                      <div className="login-social-title">
                        <H6 className={'text-muted mt-4 or'}>Ou acessar com</H6>
                      </div>

                      <div className="social my-4 ">
                        <div className="btn-showcase">
                          <a
                            className="btn btn-light"
                            href="https://www.linkedin.com/login"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Linkedin className="txt-linkedin" /> LinkedIn
                          </a>
                          <a
                            className="btn btn-light"
                            href="https://twitter.com/login?lang=en"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Twitter className="txt-twitter" />
                            twitter
                          </a>
                          <a
                            className="btn btn-light"
                            href="https://www.facebook.com/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            <Facebook className="txt-fb" />
                            facebook
                          </a>
                        </div>
                      </div>

                      <P attrPara={{ className: 'text-center mb-0 ' }}>
                        {`Não possui conta?`}
                        <Link className="ms-2" to={`/pages/authentication/register-simple`}>
                          Criar Conta
                        </Link>
                      </P>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};
