import { AuthRequestBody } from '@/model/AuthRequestBody';
import { AuthResponse } from '@/model/AuthResponse';
import api from '@/services/api';
import { Buffer } from 'buffer';

export const doLogin = async (
  username: string,
  password: string,
  payload: AuthRequestBody,
): Promise<AuthResponse> => {
  const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
  const { data } = await api.post<AuthResponse>('/auth/v1', payload, {
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  return data;
};
