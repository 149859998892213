import React, { Fragment } from 'react';
import { ListGroupItem, ListGroupItemProps } from 'reactstrap';

const LI = (props: ListGroupItemProps): JSX.Element => (
  <Fragment>
    <ListGroupItem {...props.attrLI}>
      {props.val ? <div dangerouslySetInnerHTML={{ __html: props.val }} /> : ''} {props.children}
    </ListGroupItem>
  </Fragment>
);

export default LI;
