import React from 'react';
import './App.css';
import CustomizerProvider from './helper/customizer/CustomizerProvider';
import AnimationThemeProvider from './helper/AnimationTheme/AnimationThemeProvider';
import Routers from './route';
import { ConfirmDeleteProvider } from './hooks/useConfirmDelete';

const App: React.FC = (): JSX.Element => (
  <CustomizerProvider>
    <AnimationThemeProvider>
      <ConfirmDeleteProvider>
        <Routers />
      </ConfirmDeleteProvider>
    </AnimationThemeProvider>
  </CustomizerProvider>
);

export default App;
