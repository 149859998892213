import { FormErrors } from '@/hooks/useForm';
import { Event } from '@/model/Event';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale/pt-BR';
import { Form } from 'reactstrap';
import dayjs from 'dayjs';
import { SimpleStore } from '@/model/SimpleStore';
import { Operator } from '@/model/Operator';
import { OptionProps, SelectAutoComplete } from '@/components/Select';

registerLocale('ptBR', ptBR);

interface StateProps {
  stores: SimpleStore[];
  operators: Operator[];
  formData: Event;
  formErrors: FormErrors<Event>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof Event, V>(name: N, value: V) => void;
  onGetOperators: (storeId: string) => void;
}

type Props = StateProps & DispatchProps;

export const FilterContent: React.FC<Props> = ({
  stores,
  operators,
  formData,
  formErrors,
  onChangeInput,
  onGetOperators,
}): JSX.Element => {
  const storeOptions: OptionProps[] =
    stores && stores.length > 0
      ? stores.map(data => ({
          value: data.id,
          label: data.name,
        }))
      : [];

  const operatorOptions: OptionProps[] =
    operators && operators.length > 0
      ? operators.map(data => ({
          value: data.id,
          label: data.name,
        }))
      : [];
  const toDate = (value: string): Date => {
    if (value && value.trim().length > 0) {
      const date = dayjs(value, 'DD/MM/YYYY HH:mm:ss');
      return date.toDate();
    }
    return new Date();
  };
  const handleChange = (date: Date | null): void => {
    if (date) {
      onChangeInput('eventDate', dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
    }
  };

  return (
    <div className="filter-modal-content">
      <Form noValidate={true} onSubmit={(e): void => e.preventDefault()}>
        <SelectAutoComplete
          label="Loja"
          options={storeOptions}
          name="store"
          placeholder="Selecione a loja"
          defaultValue={formData.store}
          onChange={value => {
            onChangeInput('store', value);
            onGetOperators(value);
          }}
          error={formErrors.store && formErrors.store[0]}
        />
        {formData.store && operators && operators.length > 0 && (
          <SelectAutoComplete
            label="Operador"
            options={operatorOptions}
            name="operator"
            placeholder="Selecione o operador"
            defaultValue={formData.operator}
            onChange={value => onChangeInput('operator', value)}
            error={formErrors.operator && formErrors.operator[0]}
          />
        )}
        <div className="datepicker-here" data-language="pt-BR">
          <DatePicker
            className="form-control digits"
            selected={toDate(formData.eventDate)}
            onChange={date => handleChange(date)}
            locale="ptBR"
            inline
          />
        </div>
      </Form>
    </div>
  );
};
