import React from 'react';
import Select from 'react-select';
import { customStyles } from './style';

export interface OptionProps {
  value: string;
  label: string;
}

interface SelectAutoCompleteProps {
  name: string;
  label?: string;
  error?: string;
  onChange: (newValue: string) => void;
  options: OptionProps[];
  placeholder?: string;
  style?: React.CSSProperties;
  wrapperClass?: string;
  defaultValue?: string;
}

export const SelectAutoComplete: React.FC<SelectAutoCompleteProps> = props => (
  <>
    <div className={`mb-4 flex-column ${props.wrapperClass}`}>
      {props.label && (
        <label htmlFor={props.name} className="input-label">
          {props.label}
        </label>
      )}

      <Select
        options={props.options}
        placeholder={props.placeholder}
        onChange={isValue => (isValue ? props.onChange(isValue.value) : undefined)}
        styles={customStyles()}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        value={props.options.find((option: OptionProps) => option.value === props.defaultValue)}
        defaultValue={props.options.find(
          (option: OptionProps) => option.value === props.defaultValue,
        )}
      />

      {props.error && (
        <span role="alert" className="alert-error">
          {props.error}
        </span>
      )}
    </div>
  </>
);
