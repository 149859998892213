import Breadcrumbs from '@/components/Breadcrumbs';
import { CardVideo } from '@/components/CardVideo';
import { ActionProps, Dialog } from '@/components/Dialog';
import Loader, { LoaderStatus } from '@/components/Loader';
import { Event } from '@/model/Event';
import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import FilterVector from '@/assets/images/FilterVector';
import H5 from '@/components/H5';
import { ShouldShowModal } from '../../types';
import { FilterContent } from '../../components/FilterContent';
import { CouponContent } from '../../components/CouponContent';

interface StateProps {
  status: LoaderStatus;
  amount: number;
  events: Event[];
  title: string;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formData: Event;
  formErrors: FormErrors<Event>;
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
  }) => void;
  onChangeInput: <N extends keyof Event, V>(name: N, value: V) => void;
  onFilter: () => void;
  clearFilter: () => void;
}

type Props = StateProps & DispatchProps;

export const DiscountUI: React.FC<Props> = ({
  status,
  amount,
  events,
  title,
  visible,
  onToggle,
  onShouldShowModal,
  shouldShowModal,
  formData,
  formErrors,
  onChangeInput,
  onFilter,
  clearFilter,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'secondary',
  };
  const renderActionDialogToFilter: ActionProps = {
    title: 'Aplicar',
    onClick: (): void => onFilter(),
    theme: 'primary',
  };
  return (
    <Fragment>
      <Loader status={status} />
      <Breadcrumbs
        mainTitle="Descontos"
        parent="Soluções"
        subParent="Safe Checkout"
        title="Descontos"
      />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={shouldShowModal !== ShouldShowModal.filter}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.coupon]: {},
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: renderActionDialogToFilter,
            [ShouldShowModal.coupon]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formData}
                formErrors={formErrors}
                onChangeInput={onChangeInput}
              />
            ),
            [ShouldShowModal.coupon]: <CouponContent />,
          }[shouldShowModal]
        }
      </Dialog>
      <Container fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <H5>{`${amount} Descontos`}</H5>
          </div>
          <div className="button-filter-container">
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.filter,
                    newTitleModal: '',
                  });
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <Row className="widget-grid">
          {events &&
            events.length > 0 &&
            events.map(data => (
              <CardVideo key={data.id} event={data} onShouldShowModal={onShouldShowModal} />
            ))}
        </Row>
      </Container>
    </Fragment>
  );
};
