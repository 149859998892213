import React, { useEffect, useState } from 'react';
import { Widgets2ChartData } from '@/feature/dashboard/screens/components/Widgets2';
import { EventsXBilling } from '@/model/EventsXBilling';
import { LoaderStatus } from '@/components/Loader';
import { TotalStoreIndicator } from '@/model/TotalStoreIndicator';
import { TotalOperatorIndicator } from '@/model/TotalOperatorIndicator';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { TotalEventsXBilling } from '@/model/TotalEventsXBilling';
import { IndicatorUI } from './ui';
import {
  initialEventsXBilling,
  initialTotalEventsChartData,
  initialTotalEventsXBilling,
} from '../types';
import { getIndicators } from '../services';

export const IndicatorScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [totalEventsChartData, setTotalEventsChartData] = useState<Widgets2ChartData>(
    initialTotalEventsChartData,
  );
  const [totalEventsXBilling, setTotalEventsXBilling] = useState<TotalEventsXBilling>(
    initialTotalEventsXBilling,
  );
  const [eventsXBilling, setEventsXBilling] = useState<EventsXBilling>(initialEventsXBilling);
  const [totalStoreIndicators, setTotalStoreIndicators] = useState<TotalStoreIndicator[]>([]);
  const [totalOperatorIndicators, setTotalOperatorIndicators] = useState<TotalOperatorIndicator[]>(
    [],
  );

  const handleOnFetchIndicators = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getIndicators();
      const newTotalEventsChartData = {
        ...totalEventsChartData,
        series: response.totalEvents.series,
      };
      if (newTotalEventsChartData.options.xaxis) {
        newTotalEventsChartData.options.xaxis.categories = response.totalEvents.categories;
      }
      setTotalEventsChartData(newTotalEventsChartData);
      setEventsXBilling(response.eventsXBilling);
      setTotalEventsXBilling(response.totalEventsXBilling);
      setTotalStoreIndicators(response.totalStoreIndicators);
      setTotalOperatorIndicators(response.totalOperatorIndicators);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  useEffect(() => {
    handleOnFetchIndicators();
  }, []);
  return (
    <IndicatorUI
      status={status}
      totalEventsChartData={totalEventsChartData}
      eventsXBilling={eventsXBilling}
      totalEventsXBilling={totalEventsXBilling}
      totalStoreIndicators={totalStoreIndicators}
      totalOperatorIndicators={totalOperatorIndicators}
    />
  );
};
