import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileText, LogIn, Mail, User } from 'react-feather';
import CustomizerContext from '@/helper/customizer';
import man from '@/assets/images/profile.png';

// import { LI, UL, Image, P } from '../../../AbstractElements';

// import { Account, Admin, Inbox, LogOut, Taskboard } from '../../../Constant';
import { REACT_APP_AUTH } from '@/utils/config';
import { AuthResponse } from '@/model/AuthResponse';
import Image from '../Image';
import UL from '../ListGroup/UL';
import LI from '../ListGroup/ListItem';
import P from '../P';

const UserHeader = (): JSX.Element => {
  const history = useNavigate();
  const [profile, setProfile] = useState('');
  const [name, setName] = useState<string>();
  const { layoutURL } = useContext(CustomizerContext);
  const appAuth = String(REACT_APP_AUTH);

  useEffect(() => {
    const storage = localStorage.getItem(appAuth);
    if (storage) {
      const auth = JSON.parse(storage) as AuthResponse;
      setProfile(auth.user.profilePicture ? auth.user.profilePicture : man);
      setName(auth.user.name);
    }
  }, []);

  const Logout = (): void => {
    localStorage.removeItem(appAuth);
    history(`/login`);
  };

  const UserMenuRedirect = (redirect: string): void => {
    history(redirect);
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <Image
          attrImage={{
            className: 'b-r-10 m-0',
            src: `${profile}`,
            alt: '',
            style: {
              maxWidth: '35px',
            },
          }}
        />
        <div className="media-body">
          <span>{name}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
            Cliente <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`/app/users/profile/${layoutURL}`),
          }}
        >
          <User />
          <span>Account </span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`/app/email-app/${layoutURL}`),
          }}
        >
          <Mail />
          <span>Inbox</span>
        </LI>
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`/app/todo-app/todo/${layoutURL}`),
          }}
        >
          <FileText />
          <span>Taskboard</span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>LogOut</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
