import Breadcrumbs from '@/components/Breadcrumbs';
import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Widgets2ChartData } from '@/feature/dashboard/screens/components/Widgets2';
import { EventsXBilling } from '@/model/EventsXBilling';
import Loader, { LoaderStatus } from '@/components/Loader';
import { TotalStoreIndicator } from '@/model/TotalStoreIndicator';
import { TotalOperatorIndicator } from '@/model/TotalOperatorIndicator';
import { TotalEventsXBilling } from '@/model/TotalEventsXBilling';
import { TotalEventsComponent } from '../../components/TotalEventsComponent';
import { EventsXBillingComponent } from '../../components/EventsXBillingComponent';
import { TotalEventsXBillingComponent } from '../../components/TotalEventsXBillingComponent';
import { TotalStoreComponent } from '../../components/TotalStoreComponent';
import { TotalOperatorComponent } from '../../components/TotalOperatorComponent';

interface StateProps {
  status: LoaderStatus;
  totalEventsChartData: Widgets2ChartData;
  eventsXBilling: EventsXBilling;
  totalEventsXBilling: TotalEventsXBilling;
  totalStoreIndicators: TotalStoreIndicator[];
  totalOperatorIndicators: TotalOperatorIndicator[];
}

export const IndicatorUI: React.FC<StateProps> = ({
  status,
  totalEventsChartData,
  eventsXBilling,
  totalEventsXBilling,
  totalStoreIndicators,
  totalOperatorIndicators,
}): JSX.Element => (
  <Fragment>
    <Loader status={status} />
    <Breadcrumbs mainTitle="Indicadores" title="Indicadores" />
    <Container fluid={true}>
      <Row className="widget-grid">
        <TotalEventsComponent currencyChartData={totalEventsChartData} />
        <EventsXBillingComponent eventsXBilling={eventsXBilling} />
      </Row>
      <TotalEventsXBillingComponent totalEventsXBilling={totalEventsXBilling} />
      <TotalStoreComponent status={status} totalStoreIndicators={totalStoreIndicators} />
      <TotalOperatorComponent status={status} totalOperatorIndicators={totalOperatorIndicators} />
    </Container>
  </Fragment>
);
