import DropdownCommon from '@/components/DropdownCommon';
import SvgIcon from '@/components/SvgIcon';
import React from 'react';

export interface LightCardData {
  title: string;
  icon: string;
  price: string;
  gros?: string;
  color?: string;
}

interface Props {
  data: LightCardData;
}

const LightCardBox: React.FC<Props> = ({ data }): JSX.Element => (
  <div className="light-card balance-card widget-hover">
    <div className="svg-box">
      <SvgIcon className="svg-fill" iconId={data.icon} />
    </div>
    <div>
      <span className="f-light">{data.title}</span>
      <br />
      <span className={'mt-1 mb-0'}>{data.price}</span>
    </div>
    <div className="ms-auto text-end">
      <DropdownCommon
        dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
        options={['Hoje', 'Amanhã', 'Ontem']}
        iconName="icon-more-alt"
        btn={{ tag: 'span' }}
      />
      {data.gros && (
        <span className={`d-inline-block mt-1 font-${data.color}`} style={{ fontSize: '10px' }}>
          {data.gros}
        </span>
      )}
    </div>
  </div>
);

export default LightCardBox;
