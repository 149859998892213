import React, { useEffect, useState } from 'react';
import Course1 from '@/assets/images/course1.svg';
import Course2 from '@/assets/images/course2.svg';
import Course3 from '@/assets/images/course3.svg';
import Course4 from '@/assets/images/course4.svg';
import backArrow1 from '@/assets/images/back-arrow-1.png';
import backArrow2 from '@/assets/images/back-arrow-2.png';
import backArrow3 from '@/assets/images/back-arrow-3.png';
import { ChartSerie } from '@/model/ChartSerie';
import { toCurrency } from '@/utils/currency';
import { DashboardUI } from './ui';
import { WidgetsData } from './components/WidgetsWrapper';
import { Widgets2ChartData, Widgets2Data } from './components/Widgets2';
import {
  currencyChartDataEmpty,
  recentOrderChartEmpty,
  timeLineChartEmpty,
  widgets2Data2Empty,
  widgets2DataEmpty,
} from '../data';
import { LightCardData } from './components/LightCardBox';
import { ActivityData } from './components/ActivityCard';
import { RecentSalesData } from './components/RecentSales';
import { MyCourseData } from './components/MyCourse';
import { ScheduleListData } from './components/UpcomingSchedule';
import { getHome } from '../services';

export const DashboardScreen: React.FC = (): JSX.Element => {
  const [widgetsData, setWidgetsData] = useState<WidgetsData>({
    title: 'Cupons cancelados',
    gros: 0,
    total: 0,
    color: 'secondary',
    icon: 'cart',
  });
  const [widgetsData2, setWidgetsData2] = useState<WidgetsData>({
    title: 'Itens cancelados',
    gros: 0,
    total: 0,
    color: 'secondary',
    icon: 'cart',
  });
  const [widgetsData3, setWidgetsData3] = useState<WidgetsData>({
    title: 'Eventos de pesagem',
    gros: 0,
    total: 0,
    color: 'secondary',
    icon: 'cart',
  });
  const [widgetsData4, setWidgetsData4] = useState<WidgetsData>({
    title: 'Devoluções',
    gros: 0,
    total: 0,
    color: 'secondary',
    icon: 'return-box',
  });
  const [widgets2Data, setWidgets2Data] = useState<Widgets2Data>(widgets2DataEmpty);
  const [widgets2Data2, setWidgets2Data2] = useState<Widgets2Data>(widgets2Data2Empty);
  const [lightCardData, setLightCardData] = useState<LightCardData[]>([]);
  const [currencyChartData, setCurrencyChartData] =
    useState<Widgets2ChartData>(currencyChartDataEmpty);
  const [recentOrderChart, setRecentOrderChart] =
    useState<Widgets2ChartData>(recentOrderChartEmpty);
  const [cancelCoupons, setCancelCoupons] = useState<number>(0);
  const [cancelCouponItems, setCancelCouponItems] = useState<number>(0);
  const [activityData, setActivityData] = useState<ActivityData[]>([]);
  const [recentSalesData, setRecentSalesData] = useState<RecentSalesData[]>([]);
  const [timeLineChart, setTimeLineChart] = useState<Widgets2ChartData>(timeLineChartEmpty);
  const [myCourseData, setMyCourseData] = useState<MyCourseData[]>([]);
  const [scheduleListData, setScheduleListData] = useState<ScheduleListData[]>([]);

  const handleOnFetchInformations = async (): Promise<void> => {
    const home = await getHome();
    const newWidgetsData: WidgetsData = {
      title: 'Cupons cancelados',
      gros: home.percentCanceledCoupons,
      total: home.canceledCoupons,
      color: 'secondary',
      icon: 'cart',
    };
    setWidgetsData(newWidgetsData);
    const newWidgetsData2 = {
      title: 'Itens cancelados',
      gros: home.percentCanceledItems,
      total: home.canceledItems,
      color: 'warning',
      icon: 'cart',
    };
    setWidgetsData2(newWidgetsData2);
    const newWidgetsData3 = {
      title: 'Eventos de pesagem',
      gros: home.percentWeighEvents,
      total: home.weighEvents,
      color: 'secondary',
      icon: 'tag',
    };
    setWidgetsData3(newWidgetsData3);
    const newWidgetsData4 = {
      title: 'Devoluções',
      gros: home.percentReturns,
      total: home.returns,
      color: 'warning',
      icon: 'return-box',
    };
    setWidgetsData4(newWidgetsData4);

    const newWidgets2Data = {
      ...widgets2DataEmpty,
    };

    const newSeries: ChartSerie[] = [];
    home.eventChart.series.forEach((serie, index) => {
      if (index > 0) {
        const newData: number[] = [];
        serie.data.forEach(data => {
          newData.push(data * -1);
        });
        const newSerie = {
          ...serie,
          data: newData,
        };
        newSeries.push(newSerie);
      } else {
        newSeries.push(serie);
      }
    });
    newWidgets2Data.chart.series = newSeries;

    if (newWidgets2Data.chart.options.xaxis) {
      newWidgets2Data.chart.options.xaxis.categories = home.eventChart.categories;
    }

    newWidgets2Data.total = home.events > 1000 ? `${home.events / 1000}K` : `${home.events}`;
    setWidgets2Data(newWidgets2Data);

    const chartwWidgets2Data2 = {
      ...widgets2Data2Empty,
    };

    chartwWidgets2Data2.chart.series = [home.couponChart.series[0]];

    if (chartwWidgets2Data2.chart.options.xaxis) {
      chartwWidgets2Data2.chart.options.xaxis.categories = home.couponChart.categories;
    }

    chartwWidgets2Data2.total = home.coupons > 1000 ? `${home.coupons / 1000}K` : `${home.coupons}`;
    setWidgets2Data2(chartwWidgets2Data2);

    const grosTotal =
      (home.couponChart.series[0].totalValue || 0) - (home.couponChart.series[1].totalValue || 0);
    const grosCanc =
      home.canceledCoupons + home.canceledItems - (home.canceledCouponsOld + home.canceledItemsOld);

    setLightCardData([
      {
        title: 'Total',
        icon: 'income',
        price: toCurrency(home.couponChart.series[0].totalValue as number),
        gros: toCurrency(grosTotal),
        color: 'success',
      },
      {
        title: 'Canc.',
        icon: 'expense',
        price: toCurrency(home.canceledCoupons + home.canceledItems),
        gros: toCurrency(grosCanc),
        color: 'danger',
      },
      {
        title: 'Pesagem',
        icon: 'doller-return',
        price: 'R$ 0,00',
      },
    ]);

    const newCurrencyChartData = {
      ...currencyChartDataEmpty,
    };
    newCurrencyChartData.series = home.eventChart.series;
    if (newCurrencyChartData.options.xaxis) {
      newCurrencyChartData.options.xaxis.categories = home.eventChart.categories;
    }

    setCurrencyChartData(newCurrencyChartData);

    const newRecentOrderChart = {
      ...recentOrderChartEmpty,
    };
    newRecentOrderChart.series = [home.coupons];
    setCancelCoupons(home.canceledCoupons);
    setCancelCouponItems(home.canceledItems);

    setRecentOrderChart(newRecentOrderChart);
    const newActivityData: ActivityData[] = [];

    if (home.lastEvents && home.lastEvents.length > 0) {
      home.lastEvents.forEach(data => {
        let color = 'warning';
        if (data.title === 'Consulta') {
          color = 'warning';
        } else if (data.title === 'Sangria') {
          color = 'primary';
        } else if (data.title === 'Cupom cancelado' || data.title === 'Item cancelado') {
          color = 'secondary';
        }
        newActivityData.push({
          title: data.title,
          subTitle: data.date,
          time: data.hour,
          dis: data.description,
          color,
        });
      });
    }
    setActivityData(newActivityData);
    const newRecentSalesData: RecentSalesData[] = [];
    if (home.lastCancels) {
      home.lastCancels.forEach(data => {
        newRecentSalesData.push({
          title: data.operator,
          subTitle: `${data.camera} - ${data.elapsedTime}`,
          badge: toCurrency(data.value),
        });
      });
    }

    setRecentSalesData(newRecentSalesData);

    const newTimeLineChart = {
      ...timeLineChart,
    };
    const newTimeLineChartSeries: ApexAxisChartSeries = [];
    newTimeLineChart.series = [];
    if (home.lastAuditeds && home.lastAuditeds.length > 0) {
      const data: {
        x: any;
        y: any;
        fill?: ApexFill;
        fillColor?: string;
        strokeColor?: string;
        meta?: any;
        goals?: any;
        barHeightOffset?: number;
        columnWidthOffset?: number;
      }[] = [];
      home.lastAuditeds.forEach(lastAudited => {
        let strokeColor = '#FF3364';
        if (lastAudited.title === 'Cancelamento') {
          strokeColor = '#FF3364';
        } else if (lastAudited.title === 'Desconto') {
          strokeColor = '#54BA4A';
        } else if (lastAudited.title === 'Checklist') {
          strokeColor = '#FFAA05';
        } else if (lastAudited.title === 'Itens consultados') {
          strokeColor = '#FF3364';
        }
        data.push({
          x: lastAudited.title,
          y: [lastAudited.startAt, lastAudited.endAt],
          strokeColor,
          fillColor: 'var(--white)',
        });
      });
      newTimeLineChartSeries[0] = { data: [] };
      newTimeLineChartSeries[0].data = data;
    }
    newTimeLineChart.series = newTimeLineChartSeries;
    setTimeLineChart(newTimeLineChart);

    const newMyCourseData: MyCourseData[] = [
      {
        title: 'Processos',
        arrowImage: backArrow1,
        courseImage: Course1,
      },
      {
        title: 'Configurações',
        arrowImage: backArrow2,
        courseImage: Course2,
      },
      {
        title: 'Analise de dados',
        arrowImage: backArrow1,
        courseImage: Course3,
      },
      {
        title: 'Operação',
        arrowImage: backArrow3,
        courseImage: Course4,
      },
    ];
    setMyCourseData(newMyCourseData);

    const newScheduleListData: ScheduleListData[] = [];

    if (home.lastEventAuditeds && home.lastEventAuditeds.length > 0) {
      home.lastEventAuditeds.forEach(data => {
        let color = 'primary';
        if (data.title === 'Cupom cancelado') {
          color = 'primary';
        } else if (data.title === 'Desconto') {
          color = 'warning';
        } else if (data.title === 'Sangria') {
          color = 'secondary';
        }
        newScheduleListData.push({
          title: data.title,
          icon: 'bag',
          icon2: 'clock',
          date: data.date,
          date2: data.hour,
          color,
        });
      });
    }

    setScheduleListData(newScheduleListData);
  };

  useEffect(() => {
    handleOnFetchInformations();
  }, []);

  return (
    <DashboardUI
      widgetsData={widgetsData}
      widgetsData2={widgetsData2}
      widgetsData3={widgetsData3}
      widgetsData4={widgetsData4}
      widgets2Data={widgets2Data}
      widgets2Data2={widgets2Data2}
      lightCardData={lightCardData}
      currencyChartData={currencyChartData}
      recentOrderChart={recentOrderChart}
      cancelCoupons={cancelCoupons}
      cancelCouponItems={cancelCouponItems}
      activityData={activityData}
      recentSalesData={recentSalesData}
      timeLineChart={timeLineChart}
      myCourseData={myCourseData}
      scheduleListData={scheduleListData}
    />
  );
};
