import Breadcrumbs from '@/components/Breadcrumbs';
import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import GreetingCard from '../components/GreetingCard';
import WidgetsWrapper, { WidgetsData } from '../components/WidgetsWrapper';
import { Widgets2ChartData, Widgets2Data } from '../components/Widgets2';
import { LightCardData } from '../components/LightCardBox';
import OverallBalance from '../components/OverallBalance';
import RecentOrders from '../components/RecentOrders';
import ActivityCard, { ActivityData } from '../components/ActivityCard';
import RecentSales, { RecentSalesData } from '../components/RecentSales';
import TimelineCard from '../components/TimelineCard';
import MyCourse, { MyCourseData } from '../components/MyCourse';
import UpcomingSchedule, { ScheduleListData } from '../components/UpcomingSchedule';
import PreAccountCard from '../components/PreAccountCard';

interface StateProps {
  widgetsData: WidgetsData;
  widgetsData2: WidgetsData;
  widgetsData3: WidgetsData;
  widgetsData4: WidgetsData;
  widgets2Data: Widgets2Data;
  widgets2Data2: Widgets2Data;
  lightCardData: LightCardData[];
  currencyChartData: Widgets2ChartData;
  recentOrderChart: Widgets2ChartData;
  cancelCoupons: number;
  cancelCouponItems: number;
  activityData: ActivityData[];
  recentSalesData: RecentSalesData[];
  timeLineChart: Widgets2ChartData;
  myCourseData: MyCourseData[];
  scheduleListData: ScheduleListData[];
}

export const DashboardUI: React.FC<StateProps> = ({
  widgetsData,
  widgetsData2,
  widgetsData3,
  widgetsData4,
  widgets2Data,
  widgets2Data2,
  lightCardData,
  currencyChartData,
  recentOrderChart,
  cancelCoupons,
  cancelCouponItems,
  activityData,
  recentSalesData,
  timeLineChart,
  myCourseData,
  scheduleListData,
}): JSX.Element => (
  <Fragment>
    <Breadcrumbs mainTitle="Dashboard" title="Dashboard" />
    <Container fluid={true}>
      <Row className="widget-grid">
        <GreetingCard />
        <WidgetsWrapper
          widgetsData={widgetsData}
          widgetsData2={widgetsData2}
          widgetsData3={widgetsData3}
          widgetsData4={widgetsData4}
          widgets2Data={widgets2Data}
          widgets2Data2={widgets2Data2}
        />
        <OverallBalance lightCardData={lightCardData} currencyChartData={currencyChartData} />
        <RecentOrders
          recentOrderChart={recentOrderChart}
          cancelCoupons={cancelCoupons}
          cancelCouponItems={cancelCouponItems}
        />
        <ActivityCard activityData={activityData} />
        <RecentSales recentSalesData={recentSalesData} />
        <TimelineCard timeLineChart={timeLineChart} />
        <UpcomingSchedule scheduleListData={scheduleListData} />
        <MyCourse myCourseData={myCourseData} />
        <PreAccountCard />
      </Row>
    </Container>
  </Fragment>
);
