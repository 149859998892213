import H4 from '@/components/H4';
import SvgIcon from '@/components/SvgIcon';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { toCurrency } from '@/utils/currency';
import { WidgetsData } from './WidgetsWrapper';

interface Props {
  data: WidgetsData;
}

const Widgets1: React.FC<Props> = ({ data }): JSX.Element => (
  <Card className="widget-1">
    <CardBody>
      <div className="widget-content">
        <div className={`widget-round ${data.color}`}>
          <div className="bg-round">
            <SvgIcon className="svg-fill" iconId={`${data.icon}`} />
            <SvgIcon className="half-circle svg-fill" iconId="halfcircle" />
          </div>
        </div>
        <div>
          <H4>{toCurrency(data.total)}</H4>
          <span className="f-light">{data.title}</span>
        </div>
      </div>
      <div className={`font-${data.color} f-w-500`}>
        <i className={`icon-arrow-${data.gros < 100 ? 'down' : 'up'} icon-rotate me-1`} />
        <span>{`${data.gros < 100 ? '-' : '+'}${parseInt(`${data.gros > 100 ? data.gros - 100 : data.gros}`, undefined as unknown as number)}%`}</span>
      </div>
    </CardBody>
  </Card>
);

export default Widgets1;
