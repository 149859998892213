import { FormErrors } from '@/hooks/useForm';
import { Event } from '@/model/Event';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale/pt-BR';
import { Form } from 'reactstrap';
import dayjs from 'dayjs';

registerLocale('ptBR', ptBR);

interface StateProps {
  formData: Event;
  formErrors: FormErrors<Event>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof Event, V>(name: N, value: V) => void;
}

type Props = StateProps & DispatchProps;

export const FilterContent: React.FC<Props> = ({ formData, onChangeInput }): JSX.Element => {
  const toDate = (value: string): Date => {
    if (value && value.trim().length > 0) {
      const date = dayjs(value, 'DD/MM/YYYY HH:mm:ss');
      return date.toDate();
    }
    return new Date();
  };
  const handleChange = (
    date: Date | null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ): void => {
    if (date) {
      onChangeInput('eventDate', dayjs(date).format('DD/MM/YYYY HH:mm:ss'));
    }
  };

  return (
    <div className="filter-modal-content">
      <Form noValidate={true} onSubmit={(e): void => e.preventDefault()}>
        <div className="datepicker-here" data-language="pt-BR">
          <DatePicker
            className="form-control digits"
            selected={toDate(formData.eventDate)}
            onChange={handleChange}
            locale="ptBR"
            inline
          />
        </div>
      </Form>
    </div>
  );
};
