import H5 from '@/components/H5';
import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import DropdownCommon from '@/components/DropdownCommon';
import ScheduleListBox from './ScheduleListBox';

export interface ScheduleListData {
  title: string;
  image?: string;
  icon: string;
  icon2: string;
  date: string;
  date2: string;
  color: string;
}

interface Props {
  scheduleListData: ScheduleListData[];
}

const UpcomingSchedule: React.FC<Props> = ({ scheduleListData }): JSX.Element => (
  <Col xxl="4" md="6" className="appointment-sec box-col-6">
    <Card className="schedule-card">
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 className="m-0">Eventos auditados</H5>
          <div className="card-header-right-icon">
            <DropdownCommon
              dropdownMain={{ className: 'icon-dropdown', direction: 'start' }}
              options={['Semanal', 'Mensal', 'Anual']}
              iconName="icon-more-alt"
              btn={{ tag: 'span' }}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <ScheduleListBox data={scheduleListData} />
      </CardBody>
    </Card>
  </Col>
);

export default UpcomingSchedule;
