import { createContext } from 'react';

export interface CustomizerContextProps {
  layout: string;
  sidebarIconType: string;
  mix_background_layout: string;
  toggleIcon: boolean;
  mixLayout: boolean;
  sidebarResponsive: boolean;
  IsOpen: boolean;
  layoutURL: string;
  customizer: {
    settings: {
      layout_type: string;
      sidebar: {
        type: string;
        iconType: string;
      };
      sidebar_setting: string;
    };
    color: {
      primary_color: string;
      secondary_color: string;
      mix_background_layout: string;
    };
    router_animation: string;
  };
  setIsClose: (value: boolean) => void;
  setToggleIcon: (value: boolean) => void;
  toggleSidebarResponsive: (value: boolean) => void;
  setMixLayout: (value: boolean) => void;
  addSidebarLayouts: (value: string) => void;
  addSidebarIconType: (value: string) => void;
  addSidebarSettings: (value: string) => void;
  addLayout: (value: string) => void;
  addMixBackgroundLayout: (value: string) => void;
  addColor: (defaultColor: string, secondaryColor: string) => void;
  toggleSidebar: (value: boolean) => void;
}

const CustomizerContext = createContext<CustomizerContextProps>(
  undefined as unknown as CustomizerContextProps,
);

export default CustomizerContext;
