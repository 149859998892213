import ConfigDB from '@/components/ThemeConfig';
import { Widgets2ChartData, Widgets2Data } from './screens/components/Widgets2';

const primary = ConfigDB.data.color.primary_color;

export const widgets2DataEmpty: Widgets2Data = {
  title: 'Eventos',
  total: '0',
  chart: {
    series: [],
    options: {
      chart: {
        type: 'bar',
        width: 180,
        height: 120,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          // vertical: true,
          columnWidth: '40%',
          barHeight: '80%',
          // startingShape: 'rounded',
          // endingShape: 'rounded',
        },
      },
      colors: [primary, primary, '#F2F3F7', '#F2F3F7'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      legend: {
        show: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        min: -5,
        max: 5,
        show: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        shared: false,
        x: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
          offsetX: 0,
          offsetY: 0,
          show: false,
        },
        axisBorder: {
          offsetX: 0,
          offsetY: 0,
          show: false,
        },
        axisTicks: {
          offsetX: 0,
          offsetY: 0,
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 1760,
          options: {
            chart: {
              width: 160,
            },
          },
        },
        {
          breakpoint: 1601,
          options: {
            chart: {
              height: 110,
            },
          },
        },
        {
          breakpoint: 1560,
          options: {
            chart: {
              width: 140,
            },
          },
        },
        {
          breakpoint: 1460,
          options: {
            chart: {
              width: 120,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              width: 290,
            },
          },
        },
        {
          breakpoint: 1110,
          options: {
            chart: {
              width: 200,
            },
          },
        },
        {
          breakpoint: 700,
          options: {
            chart: {
              width: 150,
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              width: 220,
            },
          },
        },
        {
          breakpoint: 420,
          options: {
            chart: {
              width: 150,
            },
          },
        },
      ],
    },
  },
};

export const widgets2Data2Empty: Widgets2Data = {
  title: 'Cupons',
  total: '6,90k',
  chart: {
    series: [
      {
        name: 'Desktops',
        data: [],
      },
    ],
    options: {
      chart: {
        width: 200,
        height: 150,
        type: 'line',
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          enabledOnSeries: undefined,
          top: 5,
          left: 0,
          blur: 3,
          color: '#16C7F9',
          opacity: 0.3,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#16C7F9'],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      grid: {
        show: false,
      },
      tooltip: {
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 1780,
          options: {
            chart: {
              width: 180,
            },
          },
        },
        {
          breakpoint: 1680,
          options: {
            chart: {
              width: 160,
            },
          },
        },
        {
          breakpoint: 1601,
          options: {
            chart: {
              height: 110,
            },
          },
        },
        {
          breakpoint: 1560,
          options: {
            chart: {
              width: 140,
            },
          },
        },
        {
          breakpoint: 1460,
          options: {
            chart: {
              width: 120,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              width: 290,
            },
          },
        },
        {
          breakpoint: 1110,
          options: {
            chart: {
              width: 200,
            },
          },
        },
        {
          breakpoint: 700,
          options: {
            chart: {
              width: 150,
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              width: 220,
            },
          },
        },
        {
          breakpoint: 420,
          options: {
            chart: {
              width: 150,
            },
          },
        },
      ],
    },
  },
};

export const currencyChartDataEmpty: Widgets2ChartData = {
  series: [],
  options: {
    chart: {
      height: 300,
      type: 'bar',
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        // enabledOnSeries: undefined,
        top: 10,
        left: 0,
        blur: 5,
        color: '#7064F5',
        opacity: 0.35,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Rubik, sans-serif',
          colors: 'var(--chart-text-color)',
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter(val) {
          return `${val}`;
        },
        style: {
          fontSize: '12px',
          fontFamily: 'Rubik, sans-serif',
          colors: 'var(--chart-text-color)',
        },
      },
    },
    grid: {
      borderColor: 'var(--chart-dashed-border)',
      strokeDashArray: 5,
    },
    colors: ['#7064F5', '#8D83FF'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        gradientToColors: ['#7064F5', '#8D83FF'],
        opacityFrom: 0.98,
        opacityTo: 0.85,
        stops: [0, 100],
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
  },
};

export const recentOrderChartEmpty: Widgets2ChartData = {
  series: [],
  options: {
    chart: {
      height: 290,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '60%',
          background: 'var(--recent-chart-bg)',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.05,
          },
        },
        track: {
          background: '#F4F4F4',
          strokeWidth: '67%',
          margin: 0,
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 10,
            color: '#ddd',
            opacity: 1,
          },
        },

        dataLabels: {
          show: true,
          name: {
            offsetY: 30,
            show: true,
            color: '#888',
            fontSize: '17px',
            fontWeight: '500',
            fontFamily: 'Rubik, sans-serif',
          },
          value: {
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(`${val}`)}`;
            },
            offsetY: -8,
            color: '#111',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          {
            offset: 0,
            color: '#7366FF',
            opacity: 1,
          },
          {
            offset: 20,
            color: '#3EA4F1',
            opacity: 1,
          },
          {
            offset: 100,
            color: '#FFFFFF',
            opacity: 1,
          },
        ],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Total Vendas'],
    responsive: [
      {
        breakpoint: 1840,
        options: {
          chart: {
            height: 260,
          },
        },
      },
      {
        breakpoint: 1700,
        options: {
          chart: {
            height: 250,
          },
        },
      },
      {
        breakpoint: 1660,
        options: {
          chart: {
            height: 230,
            dataLabels: {
              name: {
                fontSize: '15px',
              },
            },
          },
        },
      },
      {
        breakpoint: 1561,
        options: {
          chart: {
            height: 275,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 360,
          },
        },
      },
      {
        breakpoint: 1361,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 230,
          },
        },
      },
      {
        breakpoint: 1007,
        options: {
          chart: {
            height: 240,
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 230,
          },
        },
      },
    ],
  },
};

export const timeLineChartEmpty: Widgets2ChartData = {
  series: [],
  options: {
    chart: {
      height: 305,
      type: 'rangeBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: '50%',
        dataLabels: {
          hideOverflowingLabels: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter(val, opts) {
        const label = opts.w.globals.labels[opts.dataPointIndex];
        return label;
      },
      textAnchor: 'middle',
      offsetX: 0,
      offsetY: 0,
      background: {
        enabled: true,
        foreColor: 'var(--chart-text-color)',
        padding: 10,
        borderRadius: 12,
        borderWidth: 1,
        opacity: 0.9,
      },
    },
    xaxis: {
      type: 'datetime',
      position: 'top',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      row: {
        colors: ['var(--light-background)', 'var(--white)'],
        opacity: 1,
      },
    },
    stroke: {
      width: 2,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
        },
      },
    },
    responsive: [
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 295,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 370,
          },
        },
      },
      {
        breakpoint: 575,
        options: {
          chart: {
            height: 300,
          },
        },
      },
    ],
  },
};
