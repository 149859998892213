import React, { useEffect, useState } from 'react';
import ConfigDB from '@/components/ThemeConfig';
import Context from './index';

interface CustomizerProviderProps {
  children: React.ReactNode;
}

const CustomizerProvider: React.FC<CustomizerProviderProps> = ({ children }): JSX.Element => {
  const customizer = ConfigDB.data;
  const [layout, setLayout] = useState('');
  const [sidebarIconType, setSidebarIconType] = useState('');
  const [mix_background_layout, setMixBackgroundLayout] = useState('');
  const [toggleIcon, setToggleIcon] = useState(false);
  const [mixLayout, setMixLayout] = useState(false);
  const [sidebarResponsive, setSidebarResponsive] = useState(false);
  const [IsOpen, setIsClose] = useState(false);
  const layoutValue = 'compact-wrapper';
  const layoutURL = '';

  useEffect(() => {
    setLayout(layoutValue);
    setSidebarIconType(
      localStorage.getItem('sidebar_icon_type') || ConfigDB.data.settings.sidebar.iconType,
    );
    ConfigDB.data.settings.sidebar_setting = 'default-sidebar';
  }, []);

  // Set LTR,RTL,BOX Tyoe
  const addLayout = (selectedLayout: string): void => {
    ConfigDB.data.settings.layout_type = selectedLayout;
    localStorage.setItem('layout_type', selectedLayout);
    setLayout(selectedLayout);
  };

  // Toggle sidebar
  const toggleSidebar = (toggle: boolean): void => {
    setToggleIcon(toggle);
  };

  // Multiple Sidebar Layouts
  const addSidebarLayouts = (sidebar_layout: string): void => {
    localStorage.setItem('sidebar_layout', sidebar_layout);
    ConfigDB.data.settings.sidebar.type = sidebar_layout;
    setLayout(sidebar_layout);
  };
  // SideBar Icon Sidebar
  const addSidebarIconType = (sidebar_Icon_Type: string): void => {
    localStorage.setItem('sidebar_icon_type', sidebar_Icon_Type);
    ConfigDB.data.settings.sidebar.iconType = sidebar_Icon_Type;
    setSidebarIconType(sidebar_Icon_Type);
  };

  // Add Sidebar Settings like(border ,default ,...);
  const addSidebarSettings = (settings: string): void => {
    ConfigDB.data.settings.sidebar_setting = settings;
  };

  // Add Mix layouts like (dark , light ,...)
  const addMixBackgroundLayout = (selected_mix_background_layout: string): void => {
    ConfigDB.data.color.mix_background_layout = selected_mix_background_layout;
    localStorage.setItem('mix_background_layout', selected_mix_background_layout);
    if (selected_mix_background_layout !== 'light-only') {
      setMixLayout(false);
    } else {
      setMixLayout(true);
    }
    setMixBackgroundLayout(selected_mix_background_layout);
  };

  // Add Colors
  const addColor = (default_color: string, secondary_color: string): void => {
    ConfigDB.data.color.primary_color = default_color;
    ConfigDB.data.color.secondary_color = secondary_color;
    localStorage.setItem('default_color', default_color);
    localStorage.setItem('secondary_color', secondary_color);
  };

  const toggleSidebarResponsive = (toggle: boolean): void => {
    setSidebarResponsive(toggle);
  };

  return (
    <Context.Provider
      value={{
        layout,
        sidebarIconType,
        mix_background_layout,
        toggleIcon,
        mixLayout,
        sidebarResponsive,
        IsOpen,
        layoutURL,
        customizer,
        setIsClose,
        setToggleIcon,
        toggleSidebarResponsive,
        setMixLayout,
        addSidebarLayouts,
        addSidebarIconType,
        addSidebarSettings,
        addLayout,
        addMixBackgroundLayout,
        addColor,
        toggleSidebar,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default CustomizerProvider;
