import DropdownCommon from '@/components/DropdownCommon';
import H5 from '@/components/H5';
import Image from '@/components/Image';
import P from '@/components/P';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Table } from 'reactstrap';
import User1 from '@/assets/images/user1.jpg';
import { ModalStyle } from '@/feature/cancelItem/types';

export interface RecentSalesData {
  image?: string;
  title: string;
  subTitle: string;
  badge: string;
}

interface Props {
  recentSalesData: RecentSalesData[];
}

const RecentSales: React.FC<Props> = ({ recentSalesData }): JSX.Element => {
  const getStyle = (): ModalStyle => {
    let isDark = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            isDark = true;
            break;
          }
        }
      }
    }
    if (isDark) {
      return {
        type: 0,
        background: 'transparent',
        color: 'rgba(255, 255, 255, 0.6)',
      };
    }
    return {
      type: 1,
      background: '#fff',
      color: '#212529',
    };
  };
  return (
    <Col xxl="4" md="6" className="appointment-sec box-col-6">
      <div className="appointment">
        <Card>
          <CardHeader className="card-no-border">
            <div className="header-top">
              <H5 className="m-0">Cancelamentos recentes</H5>
              <div className="card-header-right-icon">
                <DropdownCommon
                  icon={false}
                  options={['Hoje', 'Amanhã', 'Ontem']}
                  btn={{ caret: true }}
                />
              </div>
            </div>
          </CardHeader>
          <CardBody className="pt-0">
            <div className="appointment-table">
              <Table borderless responsive>
                <tbody>
                  {recentSalesData.map((item, i) => (
                    <tr key={i}>
                      <td
                        style={{
                          backgroundColor: getStyle().background,
                          color: getStyle().color,
                        }}
                      >
                        <Image
                          attrImage={{
                            className: 'img-fluid img-40 rounded-circle',
                            // eslint-disable-next-line import/no-dynamic-require, global-require
                            src: item.image ? item.image : User1,
                            alt: 'user',
                          }}
                        />
                      </td>
                      <td
                        className="img-content-box"
                        style={{
                          backgroundColor: getStyle().background,
                          color: getStyle().color,
                        }}
                      >
                        <Link
                          className="d-block f-w-500"
                          to={`${process.env.PUBLIC_URL}/app/users/profile`}
                        >
                          {item.title}
                        </Link>
                        <span className="f-light">{item.subTitle}</span>
                      </td>
                      <td
                        className="text-end"
                        style={{
                          backgroundColor: getStyle().background,
                          color: getStyle().color,
                        }}
                      >
                        <P attrPara={{ className: 'm-0 font-success' }}>{item.badge}</P>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default RecentSales;
