import H5 from '@/components/H5';
import { LoaderStatus } from '@/components/Loader';
import { CustomTable, TableColumn } from '@/components/Table';
import { TotalStoreIndicator } from '@/model/TotalStoreIndicator';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { toCurrency, toPercent } from '@/utils/currency';
import { TotalStoreDataRow } from '../types';

interface StateProps {
  status: LoaderStatus;
  totalStoreIndicators: TotalStoreIndicator[];
}

export const TotalStoreComponent: React.FC<StateProps> = ({
  status,
  totalStoreIndicators,
}): JSX.Element => {
  const totalStoreColumns: TableColumn<TotalStoreDataRow>[] = [
    {
      name: (
        <div>
          <span>Loja</span>
          <br />
          <span>Faturamento</span>
        </div>
      ),
      selector: row => row.storeAndSaleValue,
    },
    {
      name: (
        <div>
          <span>Cupons</span>
          <br />
          <span>Ticket Médio</span>
        </div>
      ),
      selector: row => row.amountCouponsAndAverageTicket,
    },
    {
      name: (
        <div>
          <span>Qtde. Cupons Cancel.</span>
          <br />
          <span>Qtde. Eventos</span>
        </div>
      ),
      selector: row => row.amountCancelCouponsAndAmountEvents,
    },
    {
      name: 'Valor Cancel.',
      selector: row => row.cancelValue,
    },
    {
      name: (
        <div>
          <span>% Cupons Cancel.</span>
          <br />
          <span>% Cancel. Vendas</span>
        </div>
      ),
      selector: row => row.percentCancelCouponAndPercenteCancelSale,
    },
    {
      name: (
        <div>
          <span>Qtde. Cancel. Itens</span>
          <br />
          <span>Valor Cancelado</span>
        </div>
      ),
      selector: row => row.amountCancelItensAndCancelItensValue,
    },
    {
      name: (
        <div>
          <span>% Itens Cancel.</span>
          <br />
          <span>% Cancel. Itens</span>
        </div>
      ),
      selector: row => row.percentCancelItemAndPercenteCancelItemSale,
    },
    {
      name: (
        <div>
          <span>Qtde. Desc.</span>
          <br />
          <span>Valor Desc.</span>
        </div>
      ),
      selector: row => row.amountDiscountsAndDiscountsValue,
    },
    {
      name: (
        <div>
          <span>% C Desc.</span>
          <br />
          <span>% V Desc.</span>
        </div>
      ),
      selector: row => row.percentDiscountsAndPercenteDiscountsSale,
    },
  ];

  const totalStoreDataTable =
    totalStoreIndicators && totalStoreIndicators.length > 0
      ? totalStoreIndicators.map(data => ({
          storeAndSaleValue: (
            <div className="div-row-table-column-total-store">
              <span>{data.store.name}</span>
              <br />
              <span>{toCurrency(data.saleValue)}</span>
            </div>
          ),
          amountCouponsAndAverageTicket: (
            <div className="div-row-table-column-total-store">
              <span>{data.amountCoupons}</span>
              <br />
              <span>{toCurrency(data.averageTicket)}</span>
            </div>
          ),
          amountCancelCouponsAndAmountEvents: (
            <div className="div-row-table-column-total-store">
              <span>{data.amountCancelCoupons}</span>
              <br />
              <span>{data.amountEvents}</span>
            </div>
          ),
          cancelValue: <span className="font-danger">{toCurrency(data.cancelValue)}</span>,
          percentCancelCouponAndPercenteCancelSale: (
            <div className="div-row-table-column-total-store">
              <span>{toPercent(data.percentCancelCoupon / 100)}</span>
              <br />
              <span>{toPercent(data.percenteCancelSale / 100)}</span>
            </div>
          ),
          amountCancelItensAndCancelItensValue: (
            <div className="div-row-table-column-total-store">
              <span>{data.amountCancelItens}</span>
              <br />
              <span>{toCurrency(data.cancelItensValue)}</span>
            </div>
          ),
          percentCancelItemAndPercenteCancelItemSale: (
            <div className="div-row-table-column-total-store">
              <span>{toPercent(data.percentCancelItem / 100)}</span>
              <br />
              <span>{toPercent(data.percenteCancelItemSale / 100)}</span>
            </div>
          ),
          amountDiscountsAndDiscountsValue: (
            <div className="div-row-table-column-total-store">
              <span>{data.amountDiscounts}</span>
              <br />
              <span>{toCurrency(data.discountsValue)}</span>
            </div>
          ),
          percentDiscountsAndPercenteDiscountsSale: (
            <div className="div-row-table-column-total-store">
              <span>{toPercent(data.percentDiscounts / 100)}</span>
              <br />
              <span>{toPercent(data.percenteDiscountsSale / 100)}</span>
            </div>
          ),
        }))
      : [];
  return (
    <Row>
      <Col xl="12" className="col-xl-12">
        <Card>
          <CardHeader className="card-no-border">
            <H5>Total por loja</H5>
          </CardHeader>
          <CardBody className="pt-0">
            <CustomTable
              columns={totalStoreColumns}
              data={totalStoreDataTable}
              numberRowsPerPage={totalStoreIndicators ? totalStoreIndicators.length : 0}
              progressPending={status === LoaderStatus.LOADING}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
