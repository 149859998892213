import { SelectAutoComplete } from '@/components/Select';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import Btn from '@/components/Button';
import { Camera } from '@/model/Camera';
import { InputText } from '@/components/InputText';
import { FromInputCamera } from '../../types';

interface StateProps {
  formData: FromInputCamera;
  formErrors: FormErrors<FromInputCamera>;
  cameras: Camera[];
  registerCameras: Camera[];
}

interface DispatchProps {
  onChangeInput: <N extends keyof FromInputCamera, V>(name: N, value: V) => void;
  onAdd: () => void;
  onChange: (index: number, cameraId: string) => void;
  onRemove: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const CameraContent: React.FC<Props> = ({
  formData,
  onChangeInput,
  formErrors,
  cameras,
  registerCameras,
  onAdd,
  onChange,
  onRemove,
}): JSX.Element => {
  const camerasOptions =
    cameras && cameras.length > 0
      ? cameras.map(data => ({
          label: data.description,
          value: data.id,
        }))
      : [];

  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="storeName"
              label="Loja"
              placeholder="Nome do usuário"
              value={formData.storeName}
              onChange={e => onChangeInput('storeName', e.target.value)}
              error={formErrors.storeName && formErrors.storeName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="departmentName"
              label="Departamento"
              placeholder="Nome do usuário"
              value={formData.departmentName}
              onChange={e => onChangeInput('departmentName', e.target.value)}
              error={formErrors.departmentName && formErrors.departmentName[0]}
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row>

      {registerCameras &&
        registerCameras.length > 0 &&
        registerCameras.map((data, index) => (
          <Row key={`${index}-${data.id}`}>
            <Col md={6}>
              <SelectAutoComplete
                label=""
                options={camerasOptions}
                name={`camera-${index}`}
                placeholder="Selecione a camera"
                defaultValue={data.id}
                onChange={value => onChange(index, value)}
                error={formErrors.storeId && formErrors.storeId[0]}
              />
            </Col>
            <Col md={6}>
              {index === 0 ? (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onAdd();
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  +
                </Btn>
              ) : (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onRemove(index);
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  -
                </Btn>
              )}
            </Col>
          </Row>
        ))}
    </Fragment>
  );
};
