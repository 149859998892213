import React, { useContext } from 'react';
import CustomizerContext from '@/helper/customizer';
import api from '@/services/api';
import { ThemeType } from '@/model/ThemeType';
import SvgIcon from '../SvgIcon';

const MoonLight = (): JSX.Element => {
  const { mixLayout, addMixBackgroundLayout } = useContext(CustomizerContext);
  // const [moonlight, setMoonlight] = useState(false);

  const MoonlightToggle = async (light: boolean): Promise<void> => {
    if (light) {
      addMixBackgroundLayout('light-only');
      document.body.className = 'light-only';
      // setMoonlight(!light);
    } else {
      addMixBackgroundLayout('dark-only');
      document.body.className = 'dark-only';
      // setMoonlight(!light);
    }
    await api.patch(`/user/v1/theme/${light ? ThemeType.NORMAL : ThemeType.DARK}`);
  };

  return (
    <li>
      <div className={`mode ${mixLayout && 'active'}`} onClick={() => MoonlightToggle(!mixLayout)}>
        <SvgIcon iconId={'moon'} />
      </div>
    </li>
  );
};

export default MoonLight;
