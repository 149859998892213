import DropdownCommon from '@/components/DropdownCommon';
import H5 from '@/components/H5';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import { Widgets2ChartData } from './Widgets2';

interface Props {
  timeLineChart: Widgets2ChartData;
}

const TimelineCard: React.FC<Props> = ({ timeLineChart }): JSX.Element => (
  <Col xxl="4" md="6" className="appointment-sec box-col-6">
    <div className="appointment">
      <Card>
        <CardHeader className="card-no-border">
          <div className="header-top">
            <H5 className="m-0">Auditorias</H5>
            <div className="card-header-right-icon">
              <DropdownCommon
                icon={false}
                options={['Hoje', 'Amanhã', 'Ontem']}
                btn={{ caret: true }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-0">
          <div className="schedule-container">
            <ReactApexChart
              height={355}
              type="rangeBar"
              options={timeLineChart.options}
              series={timeLineChart.series}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  </Col>
);

export default TimelineCard;
