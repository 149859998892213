import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from '@/components/Layout';
import { routes } from './Routes';

const AuthorizedRoutes = (): JSX.Element => (
  <>
    <Routes>
      {routes.map(({ path, Component }, i) => (
        <Fragment key={i}>
          <Route element={<AppLayout />} key={i}>
            <Route path={path} element={Component} />
          </Route>
        </Fragment>
      ))}
    </Routes>
  </>
);

export default AuthorizedRoutes;
