import Breadcrumbs from '@/components/Breadcrumbs';
import H5 from '@/components/H5';
import Loader, { LoaderStatus } from '@/components/Loader';
import SvgIcon from '@/components/SvgIcon';
import React, { Fragment, ReactNode } from 'react';
import { Container } from 'reactstrap';
import { ActionProps, Dialog } from '@/components/Dialog';
import { EventCustom } from '@/model/EventCustom';
import { FormErrors } from '@/hooks/useForm';
import Btn from '@/components/Button';
import { CustomTable } from '@/components/Table';
import Pagination from '@/components/Pagination';
import { Page } from '@/model/Page';
import { StatusType } from '@/model/StatusType';
import { columns, FormInputFilter, ShouldShowModal } from '../../types';
import { FilterContent } from '../../components/FilterContent';
import { RegisterContent } from '../../components/RegisterContent';

interface StateProps {
  status: LoaderStatus;
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formDataFilter: FormInputFilter;
  formErrorsFilter: FormErrors<FormInputFilter>;
  currentPage: Page<EventCustom, EventCustom>;
  eventCustom?: EventCustom;
  formDataRegister: EventCustom;
  formErrorsRegister: FormErrors<EventCustom>;
}

interface DispatchProps {
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    eventSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    eventSelected?: EventCustom;
  }) => void;

  onChangeInputFilter: <N extends keyof FormInputFilter, V>(name: N, value: V) => void;
  onFilter: () => void;
  clearFilter: () => void;
  onChangeInputRegister: <N extends keyof EventCustom, V>(name: N, value: V) => void;
  onFindProduct: () => void;
  onSave: () => void;
  onPaginationChange: (page: number) => void;
  onShowDelete: (eventCustom: EventCustom) => void;
}

type Props = StateProps & DispatchProps;

export const CompanyEventCustomUI: React.FC<Props> = ({
  status,
  title,
  visible,
  onToggle,
  shouldShowModal,
  onShouldShowModal,
  formDataFilter,
  formErrorsFilter,
  onChangeInputFilter,
  clearFilter,
  onFilter,
  formDataRegister,
  onChangeInputRegister,
  formErrorsRegister,
  onFindProduct,
  onSave,
  currentPage,
  onPaginationChange,
  onShowDelete,
}): JSX.Element => {
  const renderActionDialogToCancelFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => clearFilter(),
    theme: 'secondary',
  };
  const renderActionDialogToFilter: ActionProps = {
    title: 'Aplicar',
    onClick: (): void => onFilter(),
    theme: 'primary',
  };
  const renderActionDialogToCancelRegister: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'secondary',
  };
  const renderActionDialogToRegister: ActionProps = {
    title: 'Salvar',
    onClick: (): void => onSave(),
    theme: 'primary',
  };
  const isDark = (): boolean => {
    let b = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            b = true;
            break;
          }
        }
      }
    }
    return b;
  };
  const toStatus = (selectedStatus: StatusType): string => {
    let s = '';
    switch (selectedStatus) {
      case StatusType.ACTIVE:
        s = 'Ativo';
        break;
      case StatusType.INACTIVE:
        s = 'Inativo';
        break;
      default:
        s = '';
        break;
    }
    return s;
  };
  const dataTable =
    currentPage && currentPage.list && currentPage.list.length > 0
      ? currentPage.list.map(data => ({
          id: data.id,
          barcode: data.barcode,
          name: data.name,
          status: toStatus(data.status),
          actions: (
            <div className="d-flex" style={{ height: '20px' }}>
              <SvgIcon
                iconId={isDark() ? 'fill-pen' : 'stroke-pen'}
                className="mr-4 svg-icon action-icon"
                onClick={(): void => {
                  onToggle();
                  onShouldShowModal({
                    value: ShouldShowModal.register,
                    newTitleModal: data.name,
                    eventSelected: data,
                  });
                }}
              />
              <SvgIcon
                iconId={isDark() ? 'fill-trash' : 'stroke-trash'}
                className="mr-4 svg-icon action-icon"
                onClick={(): void => {
                  onShowDelete(data);
                }}
              />
            </div>
          ),
        }))
      : [];
  return (
    <Fragment>
      <Loader status={status} />
      <Breadcrumbs
        mainTitle="Empresa"
        parent="Parâmetros"
        subParent="Eventos Customizados"
        title="Empresa"
      />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={shouldShowModal === ShouldShowModal.filter ? 'right' : 'center'}
        isContentWithCard={false}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToCancelFilter,
            [ShouldShowModal.register]: renderActionDialogToCancelRegister,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: renderActionDialogToFilter,
            [ShouldShowModal.register]: renderActionDialogToRegister,
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeInput={onChangeInputFilter}
              />
            ),
            [ShouldShowModal.register]: (
              <RegisterContent
                formData={formDataRegister}
                onChangeInput={onChangeInputRegister}
                formErrors={formErrorsRegister}
                onFindProduct={onFindProduct}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <div className="pageTitle" style={{ display: 'grid' }}>
            <H5>Eventos Customizados</H5>
          </div>
          <div className="button-filter-container">
            <Btn
              size="md"
              attrBtn={{
                onClick: (): void => {
                  onShouldShowModal({
                    value: ShouldShowModal.register,
                    newTitleModal: 'Cadastrar',
                  });
                },
              }}
            >
              + Cadastrar
            </Btn>
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal({
                    value: ShouldShowModal.filter,
                    newTitleModal: 'Filtrar',
                  });
                }}
              >
                <SvgIcon
                  iconId={isDark() ? 'fill-filter' : 'stroke-filter'}
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={dataTable}
          numberRowsPerPage={currentPage.pageSize}
          progressPending={status === LoaderStatus.LOADING}
        />
        <Pagination
          currentPage={currentPage.page}
          totalCount={currentPage.total}
          pageSize={currentPage.pageSize}
          onPageChange={page => onPaginationChange(page)}
          total={currentPage.total}
        />
      </Container>
    </Fragment>
  );
};
