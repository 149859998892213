import { Page } from '@/model/Page';
import { SimpleStore } from '@/model/SimpleStore';
import { User } from '@/model/User';
import api from '@/services/api';

export const pageUser = async (request: Page<User, User>): Promise<Page<User, User>> => {
  const { data } = await api.post<Page<User, User>>('/user/v1/page', request);
  return data;
};

export const getStores = async (): Promise<SimpleStore[]> => {
  const { data } = await api.get<SimpleStore[]>('/store-event-custom/v1/store/get');
  return data;
};

export const getUser = async (id: string): Promise<User> => {
  const { data } = await api.get<User>(`/user/v1/${id}`);
  return data;
};

export const getUserStores = async (id: string): Promise<SimpleStore[]> => {
  const { data } = await api.get<SimpleStore[]>(`/user/v1/${id}/store`);
  return data;
};

export const saveUser = async (payload: User, storeId: string): Promise<void> => {
  if (payload.id) {
    await api.put('/user/v1', payload);
  } else {
    await api.post(`/user/v1/store/${storeId}`, payload);
  }
};

export const saveUserStores = async (id: string, payload: SimpleStore[]): Promise<void> => {
  await api.post<void>(`/user/v1/${id}/store`, payload);
};

export const deleteUser = async (id: string): Promise<void> => {
  await api.delete(`/user/v1/${id}`);
};
