import { Validator } from '@/hooks/useForm';
import { parsePhone } from './phone';

const isValidString = (value: string): boolean => {
  if (value === '******') {
    return true;
  }
  const newValue = value.replace(/[^0-9a-zA-Z]/g, '');

  return newValue.length > 0;
};

export const required: Validator = value => {
  const valid = isValidString(value);
  return valid ? false : 'Campo deve ser preenchido';
};

export const hasPasswordOnlyNumberCharacteres: Validator = value => {
  const rawValue = value.replace(/[^0-9a-zA-Z]/g, '');
  const onlyMixOfAlphaNumeric = /^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/;

  if (onlyMixOfAlphaNumeric.test(rawValue)) {
    return false;
  }
  return 'Sua senha deve conter ao menos uma letra e um número.';
};

export const phone: Validator = value => (parsePhone(value) ? false : 'Número de celular inválido');

export const email: Validator = value => {
  // eslint-disable-next-line max-len
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = emailRegex.test(String(value).toLowerCase());

  return isValid ? false : 'Email inválido';
};

export const validDate: Validator = value => {
  // eslint-disable-next-line max-len
  const dateRegex =
    /^(((0[1-9]|[12][0-9]|30)[-/]?(0[13-9]|1[012])|31[-/]?(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])[-/]?02)[-/]?[0-9]{4}|29[-/]?02[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;

  const isValid = dateRegex.test(String(value).toLowerCase());

  return isValid ? false : 'Data inválida';
};
