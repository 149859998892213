import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import SvgIcon from '../SvgIcon';
import H3 from '../H3';

interface Props {
  mainTitle: string;
  parent?: string;
  subParent?: string;
  title: string;
}

const Breadcrumbs: React.FC<Props> = ({ mainTitle, parent, subParent, title }): JSX.Element => (
  <Fragment>
    <Container fluid={true}>
      <div className="page-title">
        <Row>
          <Col xs="6">
            <H3>{mainTitle}</H3>
          </Col>
          <Col xs="6">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/dashboard`}>
                  <SvgIcon iconId="stroke-home" />
                </Link>
              </li>
              {parent ? <li className="breadcrumb-item">{parent}</li> : ''}
              {subParent ? <li className="breadcrumb-item">{subParent}</li> : ''}
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </Col>
        </Row>
      </div>
    </Container>
  </Fragment>
);

export default Breadcrumbs;
