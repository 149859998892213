import React, { useState } from 'react';

import ConfigDB from '@/components/ThemeConfig';
import Context from './index';

interface AnimationThemeProviderProps {
  children: React.ReactNode;
}

const AnimationThemeProvider: React.FC<AnimationThemeProviderProps> = ({
  children,
}): JSX.Element => {
  const [animation, setAnimation] = useState('');

  const routerAnimation = (layout_anim: string): void => {
    ConfigDB.data.router_animation = layout_anim;
    localStorage.setItem('animation', layout_anim);
    setAnimation(layout_anim);
  };

  return (
    <Context.Provider
      value={{
        animation,
        routerAnimation,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AnimationThemeProvider;
