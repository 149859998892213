import React, { Fragment } from 'react';

import { Col } from 'reactstrap';
import MoonLight from '../MoonLight';
import UL from '../ListGroup/UL';
import UserHeader from '../UserHeader';

const RightHeader = (): JSX.Element => (
  <Fragment>
    <Col xxl="7" xl="6" md="7" className="nav-right pull-right right-header col-8 p-0 ms-auto">
      {/* <Col md="8"> */}
      <UL attrUL={{ className: 'simple-list nav-menus flex-row' }}>
        <MoonLight />
        <UserHeader />
      </UL>
      {/* </Col> */}
    </Col>
  </Fragment>
);

export default RightHeader;
