import DropdownCommon from '@/components/DropdownCommon';
import H5 from '@/components/H5';
import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import CourseCard from './CourseCard';

export interface MyCourseData {
  title: string;
  arrowImage: string;
  courseImage: string;
}

interface Props {
  myCourseData: MyCourseData[];
}

const MyCourse: React.FC<Props> = ({ myCourseData }): JSX.Element => (
  <Col xxl="6" lg="8" className="box-col-8">
    <Card className="course-card">
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 className="m-0">Treinamentos</H5>
          <div className="card-header-right-icon">
            <DropdownCommon icon={false} options={['Mais Popular']} btn={{ caret: true }} />
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="course-main-card">
          {myCourseData.map((item, i) => (
            <CourseCard key={i} data={item} />
          ))}
        </div>
      </CardBody>
    </Card>
  </Col>
);

export default MyCourse;
