import { InputText } from '@/components/InputText';
import { FormErrors } from '@/hooks/useForm';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { SimpleStore } from '@/model/SimpleStore';
import { SelectAutoComplete } from '@/components/Select';
import eye from '@/assets/images/eye.png';
import closeEye from '@/assets/images/closeEye.png';
import { FromInputRegister } from '../../types';

interface StateProps {
  stores: SimpleStore[];
  formData: FromInputRegister;
  formErrors: FormErrors<FromInputRegister>;
  togglePassword: boolean;
}

interface DispatchProps {
  onChangeInput: <N extends keyof FromInputRegister, V>(name: N, value: V) => void;
  setTogglePassword: (value: boolean) => void;
}

type Props = StateProps & DispatchProps;

export const RegisterContent: React.FC<Props> = ({
  stores,
  formData,
  formErrors,
  onChangeInput,
  togglePassword,
  setTogglePassword,
}): JSX.Element => {
  const storeOptions =
    stores && stores.length > 0
      ? stores.map(data => ({
          label: data.name,
          value: data.id,
        }))
      : [];

  const profileOptions = [
    {
      label: 'Operador',
      value: 'OPERATION',
    },
    {
      label: 'Gerente',
      value: 'MANAGEMENT',
    },
    {
      label: 'Diretor',
      value: 'DIRECTOR',
    },
  ];

  const statusOptions = [
    {
      label: 'Ativo',
      value: 'ACTIVE',
    },
    {
      label: 'Inativo',
      value: 'INACTIVE',
    },
  ];
  return (
    <Fragment>
      {stores && stores.length > 1 && (
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <SelectAutoComplete
                label="Loja"
                options={storeOptions}
                name="store"
                placeholder="Selecione a Loja"
                defaultValue={formData.store}
                onChange={value => onChangeInput('store', value)}
                error={formErrors.store && formErrors.store[0]}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="name"
              label="Nome"
              placeholder="Nome do usuário"
              value={formData.name}
              onChange={e => onChangeInput('name', e.target.value)}
              error={formErrors.name && formErrors.name[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="email"
              label="E-mail"
              placeholder="E-mail do usuário"
              value={formData.email}
              onChange={e => onChangeInput('email', e.target.value)}
              error={formErrors.email && formErrors.email[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="password"
              label="Senha"
              type={togglePassword ? 'text' : 'password'}
              placeholder="******"
              value={formData.password}
              onChange={e => onChangeInput('password', e.target.value)}
              error={formErrors.password && formErrors.password[0]}
              renderForward={
                <div
                  className="show-hide"
                  onClick={() => setTogglePassword(!togglePassword)}
                  style={{ cursor: 'pointer' }}
                >
                  {togglePassword ? (
                    <img
                      className="d-flex justify-content-center align-items-center passwordIcon"
                      src={eye}
                    />
                  ) : (
                    <img
                      className="d-flex justify-content-center align-items-center passwordIcon"
                      src={closeEye}
                    />
                  )}
                </div>
              }
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="telephone"
              label="Telefone"
              placeholder="Telefone do usuário"
              value={formData.telephone}
              onChange={e => onChangeInput('telephone', e.target.value)}
              error={formErrors.telephone && formErrors.telephone[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="birthday"
              label="Data de nascimento"
              placeholder="Data de nascimento do usuário"
              value={formData.birthday}
              onChange={e => onChangeInput('birthday', e.target.value)}
              maxLength={10}
              error={formErrors.birthday && formErrors.birthday[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectAutoComplete
              label="Pefil"
              options={profileOptions}
              name="profile"
              placeholder="Selecione o perfil"
              defaultValue={formData.profile}
              onChange={value => onChangeInput('profile', value)}
              error={formErrors.profile && formErrors.profile[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectAutoComplete
              label="Status"
              options={statusOptions}
              name="status"
              placeholder="Selecione o perfil"
              defaultValue={formData.status}
              onChange={value => onChangeInput('status', value)}
              error={formErrors.status && formErrors.status[0]}
            />
          </FormGroup>
        </Col>
      </Row>
    </Fragment>
  );
};
