import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  DropdownToggleProps,
} from 'reactstrap';

interface Props {
  dropdownMain?: DropdownProps;
  icon?: boolean;
  iconName?: string;
  btn?: DropdownToggleProps;
  options: string[];
}

const DropdownCommon: React.FC<Props> = ({ dropdownMain, icon = true, iconName, btn, options }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = (): void => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown {...dropdownMain} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle {...btn}>
        {icon && <i className={iconName}></i>}
        {!icon && options[0]}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((item, i) => (
          <DropdownItem key={i}>{item}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownCommon;
