import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import cubaimg from '@/assets/images/logo-icon.png';
import CustomizerContext from '@/helper/customizer';

const SidebarIcon = (): JSX.Element => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <div className="logo-icon-wrapper">
      <Link to={`/dashboard/default/${layoutURL}`}>
        <img className="img-fluid" src={cubaimg} alt="" />
      </Link>
    </div>
  );
};

export default SidebarIcon;
