import React, { useEffect, useState } from 'react';
import { LoaderStatus } from '@/components/Loader';
import { useDialog } from '@/hooks/useDialog';
import * as validators from '@/utils/validators';
import { useForm } from '@/hooks/useForm';
import { SimpleStore } from '@/model/SimpleStore';
import { getStores } from '@/feature/user/services';
import { AxiosError } from 'axios';
import { CustomError } from '@/model/CustomError';
import { getErrorMessage } from '@/utils/errorMessage';
import { toast } from 'react-toastify';
import { ProductDepartment } from '@/model/ProductDepartment';
import { Checklist } from '@/model/Checklist';
import { Camera } from '@/model/Camera';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { FromInputCamera, FromInputRegister, ShouldShowModal } from '../types';
import { ConfigureChecklistUI } from './ui';
import {
  getCameras,
  getChecklist,
  getChecklists,
  getDepartments,
  getRegisterCameras,
  removeChecklist,
  saveCameras,
  saveChecklist,
} from '../services';
import { DeleteContent } from '../components/DeleteContent';

export const ConfigureChecklistScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.FILTER);
  const [checklists, setChecklists] = useState<Checklist[]>([]);
  const [stores, setStores] = useState<SimpleStore[]>([]);
  const [departments, setDepartments] = useState<ProductDepartment[]>([]);
  const [registerDepartments, setRegisterDepartments] = useState<ProductDepartment[]>([]);
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [registerCameras, setRegisterCameras] = useState<Camera[]>([]);

  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const confirmDelete = useConfirmDelete();

  const handleOnGetChecklists = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getChecklists();
      setChecklists(response);
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    onChangeInput: onChangeInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm<FromInputRegister>({
    initialData: {
      storeId: '',
    },
    validators: {
      storeId: [validators.required],
    },
  });

  const {
    formData: formDataCamera,
    formErrors: formErrorsCamera,
    onChangeInput: onChangeInputCamera,
    isFormValid: isFormValidCamera,
    resetForm: resetFormCamera,
  } = useForm<FromInputCamera>({
    initialData: {
      storeId: '',
      storeName: '',
      departmentId: '',
      departmentName: '',
    },
    validators: {
      storeId: [validators.required],
      storeName: [validators.required],
      departmentId: [validators.required],
      departmentName: [validators.required],
    },
  });

  const handleOnGetDepartments = async (): Promise<void> => {
    const response = await getDepartments();
    setDepartments(response);
  };

  const handleOnGetStores = async (): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getStores();
      setStores(response);
      await handleOnGetDepartments();
      if (response && response.length > 0) {
        if (response.length === 1) {
          onChangeInputRegister('storeId', response[0].id);
        }
      }
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetChecklist = async (storeId: string, departmentId: string): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getChecklist(storeId, departmentId);
      onChangeInputRegister('storeId', response.store.id);
      setRegisterDepartments(response.departments);
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetCameras = async (storeId: string, departmentId: string): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const department = await getChecklist(storeId, departmentId);
      onChangeInputCamera('storeId', department.store.id);
      onChangeInputCamera('storeName', department.store.name);
      onChangeInputCamera('departmentId', department.departments[0].id);
      onChangeInputCamera('departmentName', department.departments[0].name);
      const response = await getCameras(storeId, departmentId);
      setCameras(response);
      const reponseRegister = await getRegisterCameras(storeId, departmentId);
      if (reponseRegister.length === 0) {
        setRegisterCameras([{} as Camera]);
      } else {
        setRegisterCameras(reponseRegister);
      }
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    storeId,
    departmentId,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    storeId?: string;
    departmentId?: string;
  }): Promise<void> => {
    await handleOnGetStores();
    if (value === ShouldShowModal.DEPARTMENT && storeId && departmentId) {
      await handleOnGetChecklist(storeId, departmentId);
    } else if (value === ShouldShowModal.DEPARTMENT) {
      setRegisterDepartments([{} as ProductDepartment]);
    } else if (value === ShouldShowModal.CAMERA && storeId && departmentId) {
      await handleOnGetCameras(storeId, departmentId);
    }
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
  };

  const handleOnFilter = async (): Promise<void> => {
    onToggle();
  };

  const handleOnAddRegisterDepartments = (): void => {
    const newRegisterDepartments: ProductDepartment[] = [];
    const newRegisterDepartment = {} as ProductDepartment;
    registerDepartments.forEach(data => {
      newRegisterDepartments.push(data);
    });
    newRegisterDepartments.push(newRegisterDepartment);
    setRegisterDepartments(newRegisterDepartments);
  };

  const handleOnChangeRegisterDepartments = (index: number, departmentId: string): void => {
    const newRegisterDepartments: ProductDepartment[] = [];
    registerDepartments.forEach((data, i) => {
      if (i === index) {
        newRegisterDepartments.push({ id: departmentId } as ProductDepartment);
      } else {
        newRegisterDepartments.push(data);
      }
    });
    setRegisterDepartments(newRegisterDepartments);
  };

  const handleOnRemoveRegisterDepartment = (index: number): void => {
    const newRegisterDepartments: ProductDepartment[] = [];
    registerDepartments.forEach((data, i) => {
      if (i !== index) {
        newRegisterDepartments.push(data);
      }
    });
    setRegisterDepartments(newRegisterDepartments);
  };

  const handleOnSave = async (): Promise<void> => {
    if (isFormValidRegister()) {
      if (!registerDepartments || registerDepartments.length === 0) {
        toast.warn('Informar pelo menos um departamento!');
      } else if (registerDepartments && registerDepartments.length > 0) {
        let existswithoutId = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < registerDepartments.length; i++) {
          if (!registerDepartments[i] || !registerDepartments[i].id) {
            existswithoutId = true;
            break;
          }
        }
        if (existswithoutId) {
          toast.warn('Existe registro sem departamento selecionado!');
        } else {
          try {
            setStatus(LoaderStatus.LOADING);
            const payload: Checklist = {
              store: { id: formDataRegister.storeId } as SimpleStore,
              departments: registerDepartments,
            };
            await saveChecklist(payload);
            await handleOnGetChecklists();
            resetFormRegister();
            onToggle();
          } catch (error) {
            const err = error as AxiosError<CustomError>;
            toast.error(getErrorMessage(err));
          } finally {
            setStatus(LoaderStatus.DEFAULT);
          }
        }
      }
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnRemoveChecklist = async (storeId: string, departmentId: string): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      await removeChecklist(storeId, departmentId);
      await handleOnGetChecklists();
      handleOnClose();
    } catch (error) {
      const err = error as AxiosError<CustomError>;
      toast.error(getErrorMessage(err));
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShowDeleteDepartment = (storeId: string, departmentId: string): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnRemoveChecklist(storeId, departmentId),
        },
      ],
    });
  };

  const handleOnAddRegisterCamera = (): void => {
    const newRegisterCameras: Camera[] = [];
    const newRegisterCamera = {} as Camera;
    registerCameras.forEach(data => {
      newRegisterCameras.push(data);
    });
    newRegisterCameras.push(newRegisterCamera);
    setRegisterCameras(newRegisterCameras);
  };

  const handleOnChangeRegisterCameras = (index: number, cameraId: string): void => {
    const newRegisterCameras: Camera[] = [];
    registerCameras.forEach((data, i) => {
      if (i === index) {
        newRegisterCameras.push({ id: cameraId } as Camera);
      } else {
        newRegisterCameras.push(data);
      }
    });
    setRegisterCameras(newRegisterCameras);
  };

  const handleOnRemoveRegisterCamera = (index: number): void => {
    const newRegisterCameras: Camera[] = [];
    registerCameras.forEach((data, i) => {
      if (i !== index) {
        newRegisterCameras.push(data);
      }
    });
    setRegisterCameras(newRegisterCameras);
  };

  const handleOnSaveCamera = async (): Promise<void> => {
    if (isFormValidCamera()) {
      let existswithoutId = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < registerCameras.length; i++) {
        if (!registerCameras[i] || !registerCameras[i].id) {
          existswithoutId = true;
          break;
        }
      }
      if (existswithoutId) {
        toast.warn('Existe registro sem camera selecionado!');
      } else {
        try {
          setStatus(LoaderStatus.LOADING);
          await saveCameras(formDataCamera.storeId, formDataCamera.departmentId, registerCameras);
          await handleOnGetChecklists();
          resetFormCamera();
          onToggle();
        } catch (error) {
          const err = error as AxiosError<CustomError>;
          toast.error(getErrorMessage(err));
        } finally {
          setStatus(LoaderStatus.DEFAULT);
        }
      }
    }
  };

  useEffect(() => {
    handleOnGetChecklists();
  }, []);
  return (
    <ConfigureChecklistUI
      status={status}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      onClearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      stores={stores}
      departments={departments}
      formDataRegister={formDataRegister}
      formErrorsRegister={formErrorsRegister}
      onChangeInputRegister={onChangeInputRegister}
      registerDepartments={registerDepartments}
      onAddRegisterDepartments={handleOnAddRegisterDepartments}
      onChangeRegisterDepartments={handleOnChangeRegisterDepartments}
      onRemoveRegisterDepartment={handleOnRemoveRegisterDepartment}
      onSave={handleOnSave}
      onShowDeleteDepartment={handleOnShowDeleteDepartment}
      checklists={checklists}
      formDataCamera={formDataCamera}
      formErrorsCamera={formErrorsCamera}
      onChangeInputCamera={onChangeInputCamera}
      cameras={cameras}
      registerCameras={registerCameras}
      onAddRegisterCamera={handleOnAddRegisterCamera}
      onChangeRegisterCameras={handleOnChangeRegisterCameras}
      onRemoveRegisterCamera={handleOnRemoveRegisterCamera}
      onSaveCamera={handleOnSaveCamera}
    />
  );
};
