import { Camera } from '@/model/Camera';
import { Checklist } from '@/model/Checklist';
import { ProductDepartment } from '@/model/ProductDepartment';
import api from '@/services/api';

export const getChecklists = async (): Promise<Checklist[]> => {
  const { data } = await api.get<Checklist[]>('/checklist/v1');
  return data;
};

export const getStoreChecklists = async (storeId: string): Promise<Checklist[]> => {
  const { data } = await api.get<Checklist[]>(`/checklist/v1/${storeId}`);
  return data;
};

export const getDepartments = async (): Promise<ProductDepartment[]> => {
  const { data } = await api.get<ProductDepartment[]>('/product-department/v1/all');
  return data;
};

export const saveChecklist = async (checklist: Checklist): Promise<Checklist> => {
  const { data } = await api.post<Checklist>('/checklist/v1', checklist);
  return data;
};

export const getChecklist = async (storeId: string, departmentId: string): Promise<Checklist> => {
  const { data } = await api.get<Checklist>(
    `/checklist/v1/store/${storeId}/department/${departmentId}`,
  );
  return data;
};

export const removeChecklist = async (storeId: string, departmentId: string): Promise<void> => {
  await api.delete(`/checklist/v1/store/${storeId}/department/${departmentId}`);
};

export const getCameras = async (storeId: string, departmentId: string): Promise<Camera[]> => {
  const { data } = await api.get<Camera[]>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera`,
  );
  return data;
};

export const getRegisterCameras = async (
  storeId: string,
  departmentId: string,
): Promise<Camera[]> => {
  const { data } = await api.get<Camera[]>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/register/camera`,
  );
  return data;
};

export const saveCameras = async (
  storeId: string,
  departmentId: string,
  payload: Camera[],
): Promise<Camera[]> => {
  const { data } = await api.post<Camera[]>(
    `/checklist/v1/store/${storeId}/department/${departmentId}/camera`,
    payload,
  );
  return data;
};
