import H5 from '@/components/H5';
import Image from '@/components/Image';
import SvgIcon from '@/components/SvgIcon';
import { Coupon } from '@/model/Coupon';
import { Event } from '@/model/Event';
import { ItemType } from '@/model/ItemType';
import { toCurrency } from '@/utils/currency';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Input, Label, Media, Row } from 'reactstrap';
import man from '@/assets/images/profile.png';
import H6 from '@/components/H6';
import { PaymentType } from '@/model/PaymentType';
import { FormErrors } from '@/hooks/useForm';
import { EventInformation } from '@/model/EventInformation';
import { ModalStyle } from '../../types';

interface StateProps {
  event?: Event;
  coupon?: Coupon;
  formData: EventInformation;
  formErrors: FormErrors<EventInformation>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof EventInformation, V>(name: N, value: V) => void;
  onSave: () => void;
}

type Props = StateProps & DispatchProps;

export const CouponContent: React.FC<Props> = ({
  event,
  coupon,
  formData,
  onChangeInput,
  onSave,
}): JSX.Element => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = (): void => {
    setWidth(window.innerWidth);
  };

  const getStyle = (): ModalStyle => {
    let isDark = false;
    const elements = document.getElementsByTagName('body');
    if (elements && elements.length > 0) {
      const element = elements[0];
      const classes = element.classList;
      if (classes && classes.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < classes.length; i++) {
          if (classes[i] === 'dark-only') {
            isDark = true;
            break;
          }
        }
      }
    }
    if (isDark) {
      return {
        type: 0,
        background: 'transparent',
        color: 'rgba(255, 255, 255, 0.6)',
      };
    }
    return {
      type: 1,
      background: '#fff',
      color: '#212529',
    };
  };

  const mountStars = (): string[] => {
    const list: string[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 5; i++) {
      list.push(
        formData.stars && formData.stars > 0 && formData.stars >= i ? 'fill-star' : 'stroke-star',
      );
    }
    return list;
  };

  const formPaymentType = (paymentType: PaymentType): string => {
    let s = '';
    switch (paymentType) {
      case PaymentType.CREDIT_CARD:
        s = 'Crédito';
        break;
      case PaymentType.DEBIT_CARD:
        s = 'Débito';
        break;
      case PaymentType.PIX:
        s = 'PIX';
        break;
      case PaymentType.EMPLOYEE_AGREEMENT:
        s = 'Empregado';
        break;
      case PaymentType.POS:
        s = 'POS';
        break;
      case PaymentType.EXCHANGE:
        s = 'Troca';
        break;
      case PaymentType.UNINFORMED:
        s = 'Não informado';
        break;
      default:
        s = 'Não informado';
        break;
    }
    return s;
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <React.Fragment>
      {event && coupon && (
        <Row className="widget-grid coupon">
          <Col className="col-xxl-7 col-sm-7 box-col-7">
            <Card className="height-equal">
              <CardHeader
                className="card-no-border"
                style={
                  width < 768
                    ? {
                        backgroundColor: getStyle().background,
                        padding: 0,
                      }
                    : { backgroundColor: getStyle().background, paddingTop: 0, paddingBottom: 0 }
                }
              >
                <div className="header-top">
                  <H5 className="m-0" style={{ color: getStyle().color }}>
                    {event.store}
                  </H5>
                  <H5 className="m-0" style={{ color: getStyle().color }}>
                    {coupon.checkout}
                  </H5>
                </div>
              </CardHeader>
              <CardBody
                style={
                  width < 768
                    ? {
                        padding: 0,
                      }
                    : { paddingTop: 0, paddingBottom: 0 }
                }
              >
                <div className="main-container-video">
                  <iframe
                    src={`https://drive.google.com/file/d/${event.fileId}/preview`}
                    frameBorder="0"
                    allow="autoplay"
                    allowFullScreen={true}
                  ></iframe>
                </div>

                {width < 768 ? (
                  <>
                    <label style={{ color: getStyle().color }}>
                      {`Cupom: ${coupon.couponNumber} , Valor : `}
                      <span className="txt-secondary">{toCurrency(coupon.total)}</span>
                    </label>
                    <div style={{ display: 'inline-flex' }}>
                      {mountStars().map((data, index) => (
                        <SvgIcon
                          key={`icon-${index}`}
                          iconId={data}
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => onChangeInput('stars', index + 1)}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="header-top">
                    <label style={{ color: getStyle().color }}>
                      {`Cupom: ${coupon.couponNumber} , Valor : `}
                      <span className="txt-secondary">{toCurrency(coupon.total)}</span>
                    </label>
                    <div style={{ display: 'inline-flex' }}>
                      {mountStars().map((data, index) => (
                        <SvgIcon
                          key={`icon-${index}`}
                          iconId={data}
                          style={{ width: '20px', height: '20px' }}
                          onClick={() => onChangeInput('stars', index + 1)}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <hr style={{ height: '1px solid', color: getStyle().color }} />
                <div>
                  {width < 768 ? (
                    <>
                      <Media style={{ width: '158px' }}>
                        <Label
                          className="col-form-label m-r-10"
                          style={{ color: getStyle().color }}
                        >
                          Salvar ocorrência:
                        </Label>
                        <Media body className="text-end icon-state switch-sm">
                          <Label className="switch">
                            <Input
                              type="checkbox"
                              disabled={false}
                              checked={formData.saveOccurrence}
                              onChange={() =>
                                onChangeInput('saveOccurrence', !formData.saveOccurrence)
                              }
                            />
                            <span className="switch-state bg-primary"></span>
                          </Label>
                        </Media>
                      </Media>
                      {formData.saveOccurrence && (
                        <div style={{ display: 'inline-flex' }}>
                          <Media>
                            <Label
                              className="col-form-label m-r-10"
                              style={{ color: getStyle().color }}
                            >
                              Desvio:
                            </Label>
                            <Media body className="text-end icon-state switch-sm">
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  disabled={false}
                                  checked={formData.deviation}
                                  onChange={() => {
                                    onChangeInput('deviation', !formData.deviation);
                                    onChangeInput('operationalError', formData.deviation);
                                  }}
                                />
                                <span className="switch-state bg-primary"></span>
                              </Label>
                            </Media>
                          </Media>
                          <Media style={{ marginLeft: '15px' }}>
                            <Label
                              className="col-form-label m-r-10"
                              style={{ color: getStyle().color }}
                            >
                              Erro operacional:
                            </Label>
                            <Media body className="text-end icon-state switch-sm">
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  disabled={false}
                                  checked={formData.operationalError}
                                  onChange={() => {
                                    onChangeInput('operationalError', !formData.operationalError);
                                    onChangeInput('deviation', formData.operationalError);
                                  }}
                                />
                                <span className="switch-state bg-primary"></span>
                              </Label>
                            </Media>
                          </Media>
                        </div>
                      )}
                    </>
                  ) : (
                    <Row>
                      <Col className="col-4">
                        <div style={{ display: 'inline-flex' }}>
                          <Media>
                            <Label
                              className="col-form-label m-r-10"
                              style={{ color: getStyle().color }}
                            >
                              Salvar ocorrência:
                            </Label>
                            <Media body className="text-end icon-state switch-sm">
                              <Label className="switch">
                                <Input
                                  type="checkbox"
                                  disabled={false}
                                  checked={formData.saveOccurrence}
                                  onChange={() =>
                                    onChangeInput('saveOccurrence', !formData.saveOccurrence)
                                  }
                                />
                                <span className="switch-state bg-primary"></span>
                              </Label>
                            </Media>
                          </Media>
                        </div>
                      </Col>
                      {formData.saveOccurrence && (
                        <>
                          <Col className="col-4">
                            <div style={{ display: 'inline-flex' }}>
                              <Media>
                                <Label
                                  className="col-form-label m-r-10"
                                  style={{ color: getStyle().color }}
                                >
                                  Desvio:
                                </Label>
                                <Media body className="text-end icon-state switch-sm">
                                  <Label className="switch">
                                    <Input
                                      type="checkbox"
                                      disabled={false}
                                      checked={formData.deviation}
                                      onChange={() => {
                                        onChangeInput('deviation', !formData.deviation);
                                        onChangeInput('operationalError', formData.deviation);
                                      }}
                                    />
                                    <span className="switch-state bg-primary"></span>
                                  </Label>
                                </Media>
                              </Media>
                            </div>
                          </Col>
                          <Col className="col-4">
                            <div style={{ display: 'inline-flex' }}>
                              <Media>
                                <Label
                                  className="col-form-label m-r-10"
                                  style={{ color: getStyle().color }}
                                >
                                  Erro operacional:
                                </Label>
                                <Media body className="text-end icon-state switch-sm">
                                  <Label className="switch">
                                    <Input
                                      type="checkbox"
                                      disabled={false}
                                      checked={formData.operationalError}
                                      onChange={() => {
                                        onChangeInput(
                                          'operationalError',
                                          !formData.operationalError,
                                        );
                                        onChangeInput('deviation', formData.operationalError);
                                      }}
                                    />
                                    <span className="switch-state bg-primary"></span>
                                  </Label>
                                </Media>
                              </Media>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  )}
                </div>
                <div className={getStyle().type === 0 ? 'dark' : ''}>
                  <textarea
                    rows={5}
                    placeholder="Observação"
                    className="form-control form-control"
                    value={formData.observation}
                    onChange={e => onChangeInput('observation', e.target.value)}
                  ></textarea>
                </div>
                <div className="header-top" style={{ paddingTop: '10px' }}>
                  <label />
                  <button className="btn btn-info" onClick={onSave}>
                    EVENTO AUDITADO
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="col-xxl-5 col-sm-5 box-col-5">
            <Card>
              <CardHeader
                className="card-no-border"
                style={
                  width < 768
                    ? {
                        backgroundColor: getStyle().background,
                        padding: 0,
                      }
                    : { backgroundColor: getStyle().background, paddingTop: 0, paddingBottom: 0 }
                }
              >
                <div className="header-top">
                  <div></div>
                  <div className="card-header-right-icon">
                    <label style={{ color: getStyle().color }}>
                      {dayjs(coupon.startDate).format('DD/MM/YYYY HH:mm')}
                    </label>
                  </div>
                </div>
              </CardHeader>
              <CardBody
                style={
                  width < 768
                    ? {
                        padding: 0,
                      }
                    : {
                        maxHeight: '500px',
                        overflowY: 'scroll',
                        paddingTop: 0,
                        paddingBottom: 0,
                      }
                }
              >
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr style={{ borderStyle: 'hidden' }}>
                        <th
                          scope="col"
                          style={{
                            backgroundColor: getStyle().background,
                            color: getStyle().color,
                            padding: 0,
                          }}
                        >
                          ITEM
                        </th>
                        <th
                          scope="col"
                          style={{
                            backgroundColor: getStyle().background,
                            color: getStyle().color,
                            padding: 0,
                          }}
                        >
                          DESCRIÇÃO
                        </th>
                        <th
                          scope="col"
                          style={{
                            backgroundColor: getStyle().background,
                            color: getStyle().color,
                            padding: 0,
                          }}
                        >
                          QTD.
                        </th>
                        <th
                          scope="col"
                          style={{
                            backgroundColor: getStyle().background,
                            color: getStyle().color,
                            padding: 0,
                          }}
                        >
                          V.UN.
                        </th>
                        <th
                          scope="col"
                          style={{
                            backgroundColor: getStyle().background,
                            color: getStyle().color,
                            padding: 0,
                          }}
                        >
                          V. TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupon.itens &&
                        coupon.itens.length > 0 &&
                        coupon.itens.map((data, index) => (
                          <tr key={`coupon-item-${index}`} style={{ borderStyle: 'hidden' }}>
                            {data.itemType === ItemType.EVENT ? (
                              <td
                                colSpan={5}
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: getStyle().background,
                                  color: getStyle().color,
                                  padding: 0,
                                }}
                              >
                                {data.content}
                              </td>
                            ) : (
                              <>
                                <th
                                  scope="row"
                                  style={{
                                    backgroundColor: getStyle().background,
                                    color: getStyle().color,
                                    padding: 0,
                                    textAlign: 'center',
                                  }}
                                >
                                  {data.sequence}
                                </th>
                                <td
                                  style={{
                                    backgroundColor: getStyle().background,
                                    color: getStyle().color,
                                    padding: 0,
                                  }}
                                >
                                  {data.content}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getStyle().background,
                                    color: getStyle().color,
                                    padding: 0,
                                    textAlign: 'center',
                                  }}
                                >
                                  {data.amount}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getStyle().background,
                                    color: getStyle().color,
                                    padding: 0,
                                    textAlign: 'center',
                                  }}
                                >
                                  {data.unitValue}
                                </td>
                                <td
                                  style={{
                                    backgroundColor: getStyle().background,
                                    color: getStyle().color,
                                    padding: 0,
                                    textAlign: 'center',
                                  }}
                                >
                                  {toCurrency(data.totalValue)}
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <hr style={{ height: '1px solid', color: getStyle().color }} />
                <div
                  style={
                    width < 768
                      ? {
                          width: '100%',
                          padding: 0,
                        }
                      : {
                          width: '100%',
                          paddingTop: '30px',
                          paddingLeft: '15px',
                          paddingRight: '15px',
                          paddingBottom: '0px',
                        }
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: getStyle().type === 0 ? '#1D1E26' : '',
                      paddingTop: '15px',
                      paddingBottom: '15px',
                      borderRadius: '5px',
                    }}
                  >
                    <div
                      style={{ borderRightStyle: 'solid', width: '50%', borderRightWidth: '1px' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Image
                          attrImage={{
                            src: man,
                            alt: 't-shirt',
                            class: 'rounded-circle float-start chat-user-img img-30 media',
                            style: {
                              'margin-left': '10px',
                            },
                          }}
                        />
                        <div style={{ paddingRight: '10px' }}>
                          <span className="f-light f-12" style={{ color: getStyle().color }}>
                            Operadora
                          </span>
                          <H6
                            className="f-14 mb-0"
                            style={{ color: getStyle().color, minWidth: '110px' }}
                          >
                            {event.operator}
                          </H6>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Image
                          attrImage={{
                            src: man,
                            alt: 't-shirt',
                            class: 'rounded-circle float-start chat-user-img img-30 media',
                            style: {
                              'margin-left': '10px',
                            },
                          }}
                        />
                        <div style={{ paddingRight: '10px' }}>
                          <span className="f-light f-12" style={{ color: getStyle().color }}>
                            Fiscal
                          </span>
                          <H6
                            className="f-14 mb-0"
                            style={{ color: getStyle().color, minWidth: '110px' }}
                          >
                            Não Informado
                          </H6>
                        </div>
                      </div>
                    </div>
                    <div>
                      <table>
                        <tr>
                          <td style={{ textAlign: 'right' }}>
                            <span className="f-light f-12" style={{ color: getStyle().color }}>
                              SUBTOTAL:
                            </span>
                          </td>
                          <td>
                            <span className="f-light f-12" style={{ color: getStyle().color }}>
                              {toCurrency(coupon.subtotal)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: 'right' }}>
                            <span className="f-light f-12" style={{ color: getStyle().color }}>
                              DESCONTO:
                            </span>
                          </td>
                          <td>
                            <span
                              className="f-light f-12 txt-danger"
                              style={{ color: getStyle().color }}
                            >
                              {toCurrency(coupon.discount)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: 'right' }}>
                            <span className="f-light f-12" style={{ color: getStyle().color }}>
                              TOTAL:
                            </span>
                          </td>
                          <td>
                            <span
                              className="f-light f-12 txt-success"
                              style={{ color: getStyle().color }}
                            >
                              {toCurrency(coupon.total)}
                            </span>
                          </td>
                        </tr>
                        {coupon.payments && coupon.payments.length > 0 ? (
                          coupon.payments.map((data, index) => (
                            <tr key={`${index}-paymentType-${data}`}>
                              <td style={{ textAlign: 'right' }}>
                                <span className="f-light f-12" style={{ color: getStyle().color }}>
                                  {index === 0 ? 'PAGAMENTO:' : ''}
                                </span>
                              </td>
                              <td>
                                <span className="f-light f-12" style={{ color: getStyle().color }}>
                                  {formPaymentType(data)}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td style={{ textAlign: 'right' }}>
                              <span className="f-light f-12" style={{ color: getStyle().color }}>
                                PAGAMENTO:
                              </span>
                            </td>
                            <td>
                              <span className="f-light f-12" style={{ color: getStyle().color }}>
                                Não informado
                              </span>
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};
