import React, { Fragment, useState, useLayoutEffect, useContext } from 'react';
import { Col } from 'reactstrap';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import CustomizerContext from '@/helper/customizer';
import logo from '@/assets/images/logo.png';
import Image from '../Image';

const Leftbar = (): JSX.Element => {
  const { setToggleIcon, toggleSidebar } = useContext(CustomizerContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sidebartoggle, setSidebartoggle] = useState(true);
  const useWindowSize = (): number => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      const updateSize = (): void => {
        setSize([window.innerWidth, window.innerHeight]);
        if (window.innerWidth <= 991) {
          setToggleIcon(true);
        } else {
          setToggleIcon(false);
        }
      };
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size[0];
  };

  const width = useWindowSize();

  const openCloseSidebar = (toggle: boolean): void => {
    const sidebarWrapper = document.querySelector<Element>('.sidebar-wrapper');
    if (width <= 991) {
      toggleSidebar(!toggle);

      if (sidebarWrapper) {
        sidebarWrapper.className = 'sidebar-wrapper ';
      }
      document.querySelector<Element>('.bg-overlay1')?.classList.add('active');
    } else if (toggle) {
      toggleSidebar(!toggle);
      if (sidebarWrapper) {
        sidebarWrapper.className = 'sidebar-wrapper close_icon ';
      }
    } else {
      toggleSidebar(!toggle);
      if (sidebarWrapper) {
        sidebarWrapper.className = 'sidebar-wrapper ';
      }
    }
  };

  return (
    <Fragment>
      <Col className="header-logo-wrapper col-auto p-0" id="out_side_click">
        <div className="logo-wrapper">
          <Link to={`/dashboard`}>
            <Image
              attrImage={{
                className: 'img-fluid for-light',
                src: logo,
                alt: '',
              }}
            />
            <Image
              attrImage={{
                className: 'img-fluid for-dark',
                src: logo,
                alt: '',
              }}
            />
          </Link>
        </div>
        <div
          className="toggle-sidebar"
          onClick={() => openCloseSidebar(sidebartoggle)}
          style={window.innerWidth <= 991 ? { display: 'block' } : { display: 'none' }}
        >
          <AlignCenter className="status_toggle middle sidebar-toggle" id="sidebar-toggle" />
        </div>
      </Col>
    </Fragment>
  );
};

export default Leftbar;
