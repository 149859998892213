import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

import H6 from '@/components/H6';

import purchase from '@/assets/images/purchase.png';

const PreAccountCard: React.FC = (): JSX.Element => (
  <Col xxl="2" md="3" className="box-col-3 col-ed-none wow zoomIn">
    <Card className="purchase-card">
      <img className="img-fluid" src={purchase} alt="vector mens with leptop" />
      <CardBody className="pt-3">
        <H6 className="mb-3">
          Buy <a href="#javascript">Pro Account </a>to Explore Primium Features
        </H6>
        <Link
          className="purchase-btn btn btn-primary btn-hover-effect f-w-500"
          to="https://1.envato.market/3GVzd"
          target="_blank"
        >
          Purchase Now
        </Link>
      </CardBody>
    </Card>
  </Col>
);

export default PreAccountCard;
