import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Media } from 'reactstrap';
import H4 from '@/components/H4';
import P from '@/components/P';
import Btn from '@/components/Button';
import Image from '@/components/Image';
import CarToon from '@/assets/images/cartoon.svg';
import { REACT_APP_AUTH } from '@/utils/config';
import { AuthResponse } from '@/model/AuthResponse';

const GreetingCard: React.FC = (): JSX.Element => {
  const checkTime = (i: number): string => {
    let s = '';
    if (i < 10) {
      s = `0${i}`;
    } // add zero in front of numbers < 10
    return s;
  };
  const startTime = (): void => {
    const today = new Date();
    let h = today.getHours();
    const m = today.getMinutes();
    // var s = today.getSeconds();
    const ampm = h >= 12 ? 'PM' : 'AM';
    h %= 12;
    h = h || 12;
    const newM = checkTime(m);
    const element = document.getElementById('txt');
    if (element) {
      element.innerHTML = `${h}:${newM} ${ampm}`;
    }

    const HOURHAND = document.querySelector('#hour') as HTMLElement;
    const MINUTEHAND = document.querySelector('#minute') as HTMLElement;
    const SECONDHAND = document.querySelector('#second') as HTMLElement;

    const date = new Date();
    const hr = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();

    let hrPosition = (hr * 360) / 12 + (min * (360 / 60)) / 12;
    let minPosition = (min * 360) / 60 + (sec * (360 / 60)) / 60;
    let secPosition = (sec * 360) / 60;

    // adds degrees in an hour divide by seconds in an hour to previous time
    hrPosition += 30 / 3600;

    // adds degrees in a minuite divide by seconds in an minuite to previous time
    minPosition += 6 / 60;

    // adds degrees in a second to previous time
    secPosition += 6;

    // position clock hands to degrees values calculated above using transfrom rotate
    HOURHAND.style.transform = `rotate(${hrPosition}deg)`;
    MINUTEHAND.style.transform = `rotate(${minPosition}deg)`;
    SECONDHAND.style.transform = `rotate(${secPosition}deg)`;

    setTimeout(startTime, 500);
  };

  const [name, setName] = useState<string>();
  useEffect(() => {
    const appAuth = String(REACT_APP_AUTH);
    const storage = localStorage.getItem(appAuth);
    if (storage) {
      const auth = JSON.parse(storage) as AuthResponse;

      setName(auth.user.name.split(' ')[0]);
    }
    startTime();
  }, []);
  return (
    <Col className="col-xxl-4 col-sm-6 box-col-6">
      <Card className=" profile-box">
        <CardBody>
          <Media>
            <Media body>
              <div className="greeting-user">
                <H4 className="f-w-600">{`Bem vindo ${name}!`}</H4>
                <P>Lorem Ipsum is simply dummy text of the printing and typesetting </P>
                <div className="whatsnew-btn">
                  <Btn
                    attrBtn={{
                      color: 'transparent',
                      outline: true,
                      className: 'btn btn-outline-white',
                    }}
                  >
                    Ver perfil
                  </Btn>
                </div>
              </div>
            </Media>
            <div>
              <div className="clockbox">
                <svg id="clock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
                  <g id="face">
                    <circle className="circle" cx={300} cy={300} r="253.9" />
                    <path
                      className="hour-marks"
                      d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"
                    />
                    <circle className="mid-circle" cx={300} cy={300} r="16.2" />
                  </g>
                  <g id="hour">
                    <path className="hour-hand" d="M300.5 298V142" />
                    <circle className="sizing-box" cx={300} cy={300} r="253.9" />
                  </g>
                  <g id="minute">
                    <path className="minute-hand" d="M300.5 298V67" />
                    <circle className="sizing-box" cx={300} cy={300} r="253.9" />
                  </g>
                  <g id="second">
                    <path className="second-hand" d="M300.5 350V55" />
                    <circle className="sizing-box" cx={300} cy={300} r="253.9"></circle>
                  </g>
                </svg>
              </div>
              <div className="badge f-10 p-0" id="txt" />
            </div>
          </Media>
          <div className="cartoon">
            <Image attrImage={{ src: CarToon, alt: 'vector women with leptop' }} />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default GreetingCard;
