import { Event } from '@/model/Event';
import { Page } from '@/model/Page';
import api from '@/services/api';

export const pageEvent = async (
  eventType: string,
  request: Page<Event, Event>,
): Promise<Page<Event, Event>> => {
  const { data } = await api.post<Page<Event, Event>>(`/event/v1/page/${eventType}`, request);
  return data;
};
