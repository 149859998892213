import React, { useEffect, useState } from 'react';
import { LoaderStatus } from '@/components/Loader';
import { Page } from '@/model/Page';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useForm } from '@/hooks/useForm';
import * as validators from '@/utils/validators';
import { useDialog } from '@/hooks/useDialog';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { StatusType } from '@/model/StatusType';
import { User } from '@/model/User';
import { ThemeType } from '@/model/ThemeType';
import { updateMask as updateMaskPhone } from '@/utils/mask/phone';
import { updateMask as updateMaskDate } from '@/utils/mask/generalDate';
import { SimpleStore } from '@/model/SimpleStore';
import dayjs from 'dayjs';
import { UserUI } from './ui';
import { FormInputFilter, FromInputRegister, SaveStore, ShouldShowModal } from '../types';
import {
  deleteUser,
  getUser,
  getStores,
  pageUser,
  saveUser,
  getUserStores,
  saveUserStores,
} from '../services';
import { DeleteContent } from '../components/DeleteContent';

export const UserScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [page, setPage] = useState<Page<User, User>>({
    page: 1,
    pageSize: 10,
  });
  const [stores, setStores] = useState<SimpleStore[]>([]);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [userStores, setUserStores] = useState<SaveStore[]>([]);

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleOnFetchEvents = async (newPage: Page<User, User>): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await pageUser(newPage);
      setPage(response);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeInput: onChangeInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm<FormInputFilter>({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      filterSearch: [validators.required],
    },
  });

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    onChangeInput: onChangeInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm<FromInputRegister>({
    initialData: {
      id: '',
      store: '',
      name: '',
      email: '',
      password: '',
      status: StatusType.ACTIVE,
      telephone: '',
      birthday: '',
      profilePicture: '',
      theme: ThemeType.NORMAL,
      profile: '',
    },
    validators: {
      name: [validators.required],
      email: [validators.required, validators.email],
      password: [validators.required],
      status: [validators.required],
      telephone: [validators.required, validators.phone],
      birthday: [validators.required, validators.validDate],
      profile: [validators.required],
    },
    formatters: {
      telephone: updateMaskPhone,
      birthday: updateMaskDate,
    },
  });

  const handleOnGetStores = async (userId?: string): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getStores();
      setStores(response);
      if (response && response.length > 0) {
        if (response.length === 1) {
          onChangeInputRegister('store', response[0].id);
        }
      }
      if (userId) {
        setSelectedUserId(userId);
        const responseUserStores = await getUserStores(userId);
        const newUserStores: SaveStore[] = [];
        response.forEach(data => {
          let exists = false;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < responseUserStores.length; i++) {
            if (data.id === responseUserStores[i].id) {
              exists = true;
              break;
            }
          }
          const newUserStore: SaveStore = {
            id: data.id,
            name: data.name,
            exists,
          };
          newUserStores.push(newUserStore);
        });
        setUserStores(newUserStores);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnGetCompanyEventCustom = async (
    id: string,
    loadFormData: boolean,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await getUser(id);
      if (loadFormData) {
        onChangeInputRegister('id', response.id);
        onChangeInputRegister('name', response.name);
        onChangeInputRegister('email', response.email);
        onChangeInputRegister('password', response.password);
        onChangeInputRegister('status', response.status);
        onChangeInputRegister('telephone', response.telephone);
        const birthday = response.birthday ? dayjs(response.birthday).format('DD/MM/YYYY') : '';
        onChangeInputRegister('birthday', birthday);
        onChangeInputRegister('profilePicture', response.profilePicture);
        onChangeInputRegister('theme', response.theme);
        onChangeInputRegister('profile', response.profile);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    userSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    userSelected?: User;
  }): Promise<void> => {
    try {
      setShouldShowModal(value);
      onChangeTitle(newTitleModal);
      if (value === ShouldShowModal.register) {
        await handleOnGetStores();
        if (userSelected) {
          await handleOnGetCompanyEventCustom(userSelected.id, true);
        }
      } else if (value === ShouldShowModal.store) {
        if (userSelected) {
          await handleOnGetStores(userSelected.id);
        }
      }
      onToggle();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const toStatusType = (value: string): StatusType | undefined => {
    if (value) {
      if (value === 'ACTIVE') {
        return StatusType.ACTIVE;
      }
      if (value === 'INACTIVE') {
        return StatusType.INACTIVE;
      }
      return undefined;
    }
    return undefined;
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValidFilter()) {
      const entity = {} as User;
      if (formDataFilter.filterSearch === 'name') {
        entity.name = formDataFilter.inputSearch;
      } else if (formDataFilter.filterSearch === 'status') {
        const statusType = toStatusType(formDataFilter.inputSearch);
        if (statusType) {
          entity.status = statusType;
        }
      }
      const newPage: Page<User, User> = {
        page: 1,
        pageSize: 10,
        entity,
      };
      onToggle();
      handleOnFetchEvents(newPage);
    }
  };

  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
    resetFormFilter();
    handleOnFetchEvents({
      page: 1,
      pageSize: 10,
    });
  };

  const handleOnPaginationChange = async (newPage: number): Promise<void> => {
    handleOnFetchEvents({
      ...page,
      page: newPage,
    });
  };

  const handleOnSave = async (): Promise<void> => {
    if (isFormValidRegister()) {
      try {
        setStatus(LoaderStatus.LOADING);
        const payload = {
          name: formDataRegister.name,
          email: formDataRegister.email,
          password: formDataRegister.password,
          status: formDataRegister.status,
          telephone: formDataRegister.telephone,
          birthday: new Date(formDataRegister.birthday),
          profilePicture: formDataRegister.profilePicture,
          theme: formDataRegister.theme,
          profile: formDataRegister.profile,
        } as User;
        if (formDataRegister.id) {
          payload.id = formDataRegister.id;
        }
        const storeId = formDataRegister.store ? formDataRegister.store : stores[0].id;
        await saveUser(payload, storeId);
        resetFormRegister();
        onToggle();
        handleOnFetchEvents(page);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnChangeUserStore = (index: number, selected: boolean): void => {
    const newUserStores: SaveStore[] = [];
    userStores.forEach((data, ix) => {
      if (ix === index) {
        newUserStores.push({ ...data, exists: selected });
      } else {
        newUserStores.push(data);
      }
    });
    setUserStores(newUserStores);
  };

  const handleOnSaveStore = async (): Promise<void> => {
    if (!userStores || userStores.length === 0) {
      toast.error('Selecione pelo menos uma empresa!');
    } else {
      const payload: SimpleStore[] = [];
      userStores.forEach(data => {
        if (data.exists) {
          const newStore: SimpleStore = {
            id: data.id,
            name: data.name,
          };
          payload.push(newStore);
        }
      });
      if (payload.length > 0) {
        try {
          setStatus(LoaderStatus.LOADING);
          await saveUserStores(selectedUserId as string, payload);
          onToggle();
          handleOnFetchEvents(page);
        } catch (error) {
          const err = error as AxiosError;
          toast.error(err.message);
        } finally {
          setStatus(LoaderStatus.DEFAULT);
        }
      } else {
        toast.error('Selecione pelo menos uma empresa!');
      }
    }
  };

  const handleOnClose = (): void => confirmDelete.hide();

  const handleOnConfirmDelete = async (selectedUser: User): Promise<void> => {
    try {
      await deleteUser(selectedUser.id);
      toast.success('PDV excluído com sucesso!');
      handleOnClose();
      handleOnFetchEvents(page);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnShowDelete = (selectedUser: User): void => {
    confirmDelete.show({
      title: '',
      position: 'center',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'secondary',
          onClick: (): void => handleOnClose(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleOnConfirmDelete(selectedUser),
        },
      ],
    });
  };

  useEffect(() => {
    handleOnFetchEvents(page);
  }, []);
  return (
    <UserUI
      status={status}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      formDataFilter={formDataFilter}
      onChangeInputFilter={onChangeInputFilter}
      formErrorsFilter={formErrorsFilter}
      clearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      stores={stores}
      formDataRegister={formDataRegister}
      formErrorsRegister={formErrorsRegister}
      onChangeInputRegister={onChangeInputRegister}
      togglePassword={togglePassword}
      setTogglePassword={setTogglePassword}
      onSave={handleOnSave}
      userStores={userStores}
      onChangeUserStore={handleOnChangeUserStore}
      onSaveStore={handleOnSaveStore}
      currentPage={page}
      onPaginationChange={handleOnPaginationChange}
      onShowDelete={handleOnShowDelete}
    />
  );
};
