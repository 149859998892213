import React from 'react';
import { Col, Row } from 'reactstrap';
import LightCardBox, { LightCardData } from './LightCardBox';

interface Props {
  data: LightCardData[];
}
const LightCard: React.FC<Props> = ({ data }): JSX.Element => (
  <Col xl="3" md="12" sm="5" className="p-0">
    <Row className="g-sm-4 g-2">
      {data.map((d, i) => (
        <Col key={i} xl="12" md="4">
          <LightCardBox data={d} />
        </Col>
      ))}
    </Row>
  </Col>
);

export default LightCard;
