import React from 'react';
import { ListGroup, ListGroupProps } from 'reactstrap';
import ListItem from './ListItem';

const UL = (props: ListGroupProps): JSX.Element => (
  <ListGroup {...props.attrUL}>
    {props.listItem
      ? props.listItem.map((item: any, i: number) => (
          <ListItem val={item} attrLI={props.attrLI} key={i} />
        ))
      : props.children}
  </ListGroup>
);

export default UL;
