import { SelectAutoComplete } from '@/components/Select';
import { SimpleStore } from '@/model/SimpleStore';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FormErrors } from '@/hooks/useForm';
import { ProductDepartment } from '@/model/ProductDepartment';
import Btn from '@/components/Button';
import { FromInputRegister } from '../../types';

interface StateProps {
  stores: SimpleStore[];
  formData: FromInputRegister;
  formErrors: FormErrors<FromInputRegister>;
  departments: ProductDepartment[];
  registerDepartments: ProductDepartment[];
}

interface DispatchProps {
  onChangeInput: <N extends keyof FromInputRegister, V>(name: N, value: V) => void;
  onAddRegisterDepartments: () => void;
  onChangeRegisterDepartments: (index: number, departmentId: string) => void;
  onRemoveRegisterDepartment: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const DepartmentContent: React.FC<Props> = ({
  stores,
  formData,
  onChangeInput,
  formErrors,
  departments,
  registerDepartments,
  onAddRegisterDepartments,
  onChangeRegisterDepartments,
  onRemoveRegisterDepartment,
}): JSX.Element => {
  const storeOptions =
    stores && stores.length > 0
      ? stores.map(data => ({
          label: data.name,
          value: data.id,
        }))
      : [];

  const departmentOptions =
    departments && departments.length > 0
      ? departments.map(data => ({
          label: data.name,
          value: data.id,
        }))
      : [];

  return (
    <Fragment>
      {stores && stores.length > 1 && (
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <SelectAutoComplete
                label="Loja"
                options={storeOptions}
                name="store"
                placeholder="Selecione a Loja"
                defaultValue={formData.storeId}
                onChange={value => onChangeInput('storeId', value)}
                error={formErrors.storeId && formErrors.storeId[0]}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      {registerDepartments &&
        registerDepartments.length > 0 &&
        registerDepartments.map((data, index) => (
          <Row key={`${index}-${data.id}`}>
            <Col md={6}>
              <SelectAutoComplete
                label=""
                options={departmentOptions}
                name={`department-${index}`}
                placeholder="Selecione o departamento"
                defaultValue={data.id}
                onChange={value => onChangeRegisterDepartments(index, value)}
                error={formErrors.storeId && formErrors.storeId[0]}
              />
            </Col>
            <Col md={6}>
              {index === 0 ? (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onAddRegisterDepartments();
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  +
                </Btn>
              ) : (
                <Btn
                  size="md"
                  attrBtn={{
                    onClick: (): void => {
                      onRemoveRegisterDepartment(index);
                    },
                    style: {
                      height: '40px',
                    },
                  }}
                >
                  -
                </Btn>
              )}
            </Col>
          </Row>
        ))}
    </Fragment>
  );
};
