import H4 from '@/components/H4';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';

export interface Widgets2ChartData {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  options: ApexOptions;
}

export interface Widgets2Data {
  title: string;
  total: string;
  chart: Widgets2ChartData;
}

interface Props {
  data: Widgets2Data;
  chartClass?: string;
  mainClass?: string;
}

const Widgets2: React.FC<Props> = ({ data, chartClass, mainClass }): JSX.Element => (
  <>
    <Card className={`widget-1 widget-with-chart ${mainClass || ''}`}>
      <CardBody>
        <div>
          <H4 className="mb-1">{data.total}</H4>
          <span className="f-light">{data.title}</span>
        </div>
        <div className={`${chartClass || 'order-chart'}`}>
          {data.chart.options.chart && (
            <ReactApexChart
              type={data.chart.options.chart.type}
              height={data.chart.options.chart.height}
              options={data.chart.options}
              series={data.chart.series}
            />
          )}
        </div>
      </CardBody>
    </Card>
  </>
);

export default Widgets2;
