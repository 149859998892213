import React, { useContext } from 'react';
import { Grid } from 'react-feather';
import { Link } from 'react-router-dom';
import CustomizerContext from '@/helper/customizer';
import CubaIcon from '@/assets/images/logo.png';
import logoDark from '@/assets/images/logo_dark.png';
import Image from '../Image';

const SidebarLogo = (): JSX.Element => {
  const { mixLayout, toggleSidebar, toggleIcon, layout } = useContext(CustomizerContext);

  const openCloseSidebar = (): void => {
    toggleSidebar(!toggleIcon);
  };

  const layout1 = localStorage.getItem('sidebar_layout') || layout;

  return (
    <div className="logo-wrapper">
      {layout1 !== 'compact-wrapper dark-sidebar' &&
      layout1 !== 'compact-wrapper color-sidebar' &&
      mixLayout ? (
        <Link to={`/dashboard`}>
          <Image attrImage={{ className: 'img-fluid d-inline', src: `${CubaIcon}`, alt: '' }} />
        </Link>
      ) : (
        <Link to={`/dashboard`}>
          <Image
            attrImage={{
              className: 'img-fluid d-inline',
              src: logoDark,
              alt: '',
            }}
          />
        </Link>
      )}
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
      <div className="toggle-sidebar" onClick={openCloseSidebar}>
        <Grid className="status_toggle middle sidebar-toggle" />
      </div>
    </div>
  );
};

export default SidebarLogo;
