import { InputText } from '@/components/InputText';
import { FormErrors } from '@/hooks/useForm';
import { SimpleStore } from '@/model/SimpleStore';
import React, { Fragment } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { SelectAutoComplete } from '@/components/Select';
import { FormInputRegister } from '../../types';

interface StateProps {
  stores: SimpleStore[];
  formData: FormInputRegister;
  formErrors: FormErrors<FormInputRegister>;
}

interface DispatchProps {
  onChangeInput: <N extends keyof FormInputRegister, V>(name: N, value: V) => void;
  onFindProduct: () => void;
}

type Props = StateProps & DispatchProps;

export const RegisterContent: React.FC<Props> = ({
  stores,
  formData,
  formErrors,
  onChangeInput,
  onFindProduct,
}): JSX.Element => {
  const options =
    stores && stores.length > 0
      ? stores.map(data => ({
          label: data.name,
          value: data.id,
        }))
      : [];
  return (
    <Fragment>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectAutoComplete
              label="Loja"
              options={options}
              name="store"
              placeholder="Selecione a Loja"
              defaultValue={formData.store}
              onChange={value => onChangeInput('store', value)}
              error={formErrors.store && formErrors.store[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="barcode"
              label="Código de barras"
              placeholder="Digite o código de barras"
              value={formData.barcode}
              onChange={e => onChangeInput('barcode', e.target.value)}
              onBlur={onFindProduct}
              error={formErrors.barcode && formErrors.barcode[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      {formData.barcode && (
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <InputText
                name="name"
                label="Nome do produto"
                value={formData.name}
                onChange={e => onChangeInput('name', e.target.value)}
                error={formErrors.name && formErrors.name[0]}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};
